import Cookies from 'js-cookie';
import {
  AnalyticEventsMap,
  AnalyticsEvents
} from '../analytics';
import type { AuthResponse } from '../../types/user';
import type { LoginFlow } from '../../types/flows';

export const setTokensToStorage = (
  response: AuthResponse,
  flow: LoginFlow
) => {
  AnalyticEventsMap[AnalyticsEvents.userIdSet](response.id);

  Cookies.set('user-token', response.user_token);
  Cookies.set('cart-token', response.user_token);
  localStorage.setItem('user-token', response.user_token);
  localStorage.setItem('cart-token', response.cart_token);
  localStorage.setItem('authBy', flow);

  if (window?.BX) {
    window.BX.User = {
      NAME: response.name,
      EMAIL: response.email
    };
  }
};
