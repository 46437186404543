import React, { FC } from 'react';
import {
  Button,
  ButtonSizes,
  Icon
} from '@npm-registry/eapteka-ui';
import { SubmitFormButtonProps } from './types';
import FormButtonStyles from '../../../styles/FormButton/FormButton.scss';

export const SubmitFormButton: FC<
  SubmitFormButtonProps
> = ({
  children,
  disabled = false,
  isRequestInProgress = false
}) => (
  <Button
    className={FormButtonStyles.formButton}
    size={ButtonSizes.s}
    disabled={disabled}
    type="submit"
  >
    {isRequestInProgress && (
      <Icon
        className={FormButtonStyles.formButtonLoader}
        name="loader"
      />
    )}
    {!isRequestInProgress && children}
  </Button>
);
