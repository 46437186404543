// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eeoOGuV{position:absolute;top:0;left:0;display:flex;justify-content:center;align-items:center;width:100%;height:100%}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": `eeoOGuV`
};
export default ___CSS_LOADER_EXPORT___;
