import React from 'react';
import { Link } from '@npm-registry/eapteka-ui';
import {
  BONUS_PROGRAM_RULES,
  COMPANY_CONTRACT,
  COMPANY_POLICY
} from '../../../config';
import { addCityToPath } from '../../../helpers/addCityToPath';
import Styles from './PolicyNotice.scss';

export const PolicyNotice = () => (
  <div className={Styles.policyNotice}>
    Продолжая пользоваться сайтом и/или мобильным
    приложением, Вы{'\u00A0'}соглашаетесь с{' '}
    <Link
      className={Styles.policyNoticeLink}
      href={addCityToPath(COMPANY_POLICY)}
      target="_blank"
    >
      политикой конфиденциальности
    </Link>
    ,{' '}
    <Link
      className={Styles.policyNoticeLink}
      href={addCityToPath(COMPANY_CONTRACT)}
      target="_blank"
    >
      пользовательским соглашением
    </Link>
    {' и '}
    <Link
      className={Styles.policyNoticeLink}
      href={addCityToPath(BONUS_PROGRAM_RULES)}
      target="_blank"
    >
      правилами бонусной программы
    </Link>
  </div>
);
