import Cookies from 'js-cookie';
import type { RequestTokensMap } from '../../types/tokens';

export const getTokensFromStorage = () => ({
  userToken:
    Cookies.get('user-token') ||
    localStorage.getItem('user-token'),
  cartToken:
    Cookies.get('cart-token') ||
    localStorage.getItem('cart-token')
});

export const getRequestTokensFromStorage =
  (): RequestTokensMap => {
    const { userToken, cartToken } = getTokensFromStorage();

    const resultTokensMap: RequestTokensMap = {};

    if (userToken) {
      resultTokensMap['user-token'] = userToken;
    }

    if (cartToken) {
      resultTokensMap['cart-token'] = cartToken;
    }

    return resultTokensMap;
  };
