// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Rfci0s2{display:flex;flex-direction:row;justify-content:flex-start;align-items:flex-start;gap:14px;margin-bottom:0}.TgdEXg7{--checkbox-variant-default-border-color: #ff1f78}.VimkGEe{font-size:12px;font-weight:normal;line-height:18px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkboxGroup": `Rfci0s2`,
	"checkboxGroupError": `TgdEXg7`,
	"checkboxGroupLabel": `VimkGEe`
};
export default ___CSS_LOADER_EXPORT___;
