import React, { FormEvent, VFC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  ButtonSizes,
  ButtonVariants
} from '@npm-registry/eapteka-ui';
import clsx from 'clsx';
import {
  getRequestState,
  isAuthFormSubmitDisabled,
  setAuthFormField,
  submitEmailAuthForm
} from '../../../store/forms';
import { screenActions } from '../../../store/screen';
import { ScreenCode } from '../../../types/screens';
import { FormFields } from '../../../types/form';
import { FormWrapper } from '../../elements/FormWrapper';
import { PasswordField } from '../../elements/PasswordField';
import { PolicyBlock } from '../../elements/PolicyBlock';
import { FormGroup } from '../../elements/FormGroup';
import { BaseField } from '../../elements/BaseField';
import { SubmitFormButton } from '../../elements/SubmitFormButton';
import FormButtonStyles from '../../../styles/FormButton/FormButton.scss';
import { AuthorizationEmailFormProps } from './types';

export const AuthorizationEmailForm: VFC<
  AuthorizationEmailFormProps
> = ({ sberIDBlock, changeFlowBlock, onClose }) => {
  const dispatch = useDispatch();
  const isRequestInProgress = useSelector(getRequestState);
  const isSubmitDisable = useSelector(
    isAuthFormSubmitDisabled
  );

  const passwordResetClickHandler = () => {
    dispatch(
      screenActions.changeScreen(
        ScreenCode.passwordRecovery
      )
    );
  };

  const submitHandler = (event: FormEvent) => {
    event.preventDefault();

    dispatch(submitEmailAuthForm());
  };

  return (
    <FormWrapper title="Вход" onClose={onClose}>
      <form onSubmit={submitHandler}>
        <BaseField
          label="Почта"
          field={FormFields.email}
          setter={setAuthFormField}
        />
        <PasswordField
          label="Пароль"
          field={FormFields.password}
          setter={setAuthFormField}
        />
        <PolicyBlock />
        <FormGroup>
          <SubmitFormButton
            disabled={isSubmitDisable}
            isRequestInProgress={isRequestInProgress}
          >
            Войти
          </SubmitFormButton>
        </FormGroup>
        <FormGroup>
          <Button
            className={clsx(
              FormButtonStyles.formButton,
              FormButtonStyles.formButtonReset
            )}
            size={ButtonSizes.s}
            onClick={passwordResetClickHandler}
            variant={
              ButtonVariants.outlinedSolid as keyof typeof ButtonVariants
            }
          >
            Восстановить пароль
          </Button>
          {sberIDBlock}
        </FormGroup>
        {changeFlowBlock}
      </form>
    </FormWrapper>
  );
};
