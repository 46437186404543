import type {
  AuthResponse,
  User
} from '../../../types/user';
import { serializeBonusProgram } from './serializeBonusProgram';
import { serializeSberprime } from './serializeSberprime';

export const serializeUser = (
  user: AuthResponse
): User => ({
  id: user.id,
  name: user.name,
  phone: user.phone,
  email: user.email,
  userToken: user.user_token,
  cartToken: user.cart_token,
  register: user.register,
  birthday: user.birthday,
  refferalCode: user.refferal_code,
  callbackRoute: user.callback_route,
  favoriteStore: user.favorite_store,
  userDeleteTimeout: user.user_delete_timeout,
  sberIdEnabled: user.sber_id_enabled,
  acceptedCheckErecipe: user.accepted_check_erecipe,
  promocode: user.promocode
    ? {
        countActive: user.promocode.count_active
      }
    : null,
  bonusProgram: serializeBonusProgram(user.bonus_program),
  sberprime: serializeSberprime(user.sberprime)
});
