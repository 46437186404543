import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from '@npm-registry/eapteka-ui';

import {
  getBonusInfoProgCond,
  getIsAutoRegisterSberSpasiboToOauthProp
} from '../../../store/handbook';
import {
  BONUS_PROGRAM_TEXT,
  LOYALTY_TEXT
} from '../../../consts/langs';
import { BONUS_PROGRAM_RULES } from '../../../config';
import { addCityToPath } from '../../../helpers/addCityToPath';
import { useRegion } from '../../../hooks/useRegion';

export const LoyaltyLink = () => {
  const isAutoRegisterSberSpasiboToOauth = useSelector(
    getIsAutoRegisterSberSpasiboToOauthProp
  );
  const sberSpasiboLink = useSelector(getBonusInfoProgCond);
  const region = useRegion();

  if (
    !isAutoRegisterSberSpasiboToOauth ||
    !region?.features?.bonus_program
  ) {
    return null;
  }

  const loyaltyText = isAutoRegisterSberSpasiboToOauth
    ? LOYALTY_TEXT
    : BONUS_PROGRAM_TEXT;

  const loyaltyLink = isAutoRegisterSberSpasiboToOauth
    ? sberSpasiboLink
    : addCityToPath(BONUS_PROGRAM_RULES);

  return (
    <>
      {' и\u00A0'}
      <Link href={loyaltyLink} target="_blank">
        {loyaltyText}
      </Link>
    </>
  );
};
