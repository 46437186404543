import React, { useEffect } from 'react';
import {
  Provider,
  useDispatch,
  useSelector
} from 'react-redux';
import { showStartScreen } from '../../store/screen/thunks/showStartScreen';
import { getHandbookReceivedState } from '../../store/handbook';
import { StoreRegistry } from '../../store';
import AuthorizationModal from '../../components/blocks/AuthorizationModal';

export const AuthorizationModalEntryPointHoc = () => {
  const dispatch = useDispatch();
  const isHandbookReceived = useSelector(
    getHandbookReceivedState
  );

  useEffect(() => {
    let hookHandler = () => {
      if (isHandbookReceived) {
        dispatch(showStartScreen({}));

        hookHandler = null;
      }
    };

    if (hookHandler !== null) {
      hookHandler();
    }
  }, [dispatch, isHandbookReceived]);

  const onCloseHandle = () => {
    if (window.history.length > 1) {
      window.history.back();
    } else {
      window.location.replace('/');
    }
  };

  return (
    <AuthorizationModal onClose={() => onCloseHandle()} />
  );
};

export const AuthorizationModalEntryPoint = () => (
  <Provider store={StoreRegistry.instance.store}>
    <AuthorizationModalEntryPointHoc />
  </Provider>
);
