import React from 'react';
import { Link } from '@npm-registry/eapteka-ui';
import { addCityToPath } from '../../../../helpers/addCityToPath';
import {
  COMPANY_CONTRACT,
  COMPANY_POLICY
} from '../../../../config';
import PolicyBlock from '../../../elements/PolicyBlock';
import LoyaltyLink from '../../LoyaltyLink';

export const AuthorizationSberIdFormHOCPolicy = () => (
  <PolicyBlock>
    Даю согласие на обработку персональных данных
    {' в\u00A0'}соответствии с{' '}
    <Link
      href={addCityToPath(COMPANY_POLICY)}
      target="_blank"
    >
      политикой конфиденциальности
    </Link>
    , соглашаюсь с{' '}
    <Link
      href={addCityToPath(COMPANY_CONTRACT)}
      target="_blank"
    >
      пользовательским соглашением
    </Link>
    <LoyaltyLink />
  </PolicyBlock>
);
