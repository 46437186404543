import type {
  BonusProgramLevelsCode,
  BonusProgramLevelsMap,
  BonusProgramResponseLevelsMap
} from '../../../types/user';

export const serializeBonusLevels = (
  bonusLevels: BonusProgramResponseLevelsMap | null
): BonusProgramLevelsMap | null => {
  if (bonusLevels === null) {
    return null;
  }

  return Object.keys(bonusLevels).reduce(
    (
      bonusProgramLevels,
      levelKey: BonusProgramLevelsCode
    ) => {
      const levelItem = bonusLevels[levelKey];

      return {
        ...bonusProgramLevels,
        [levelKey]: {
          name: levelItem.name,
          percent: levelItem.percent,
          percentGet: levelItem.percent_get,
          canHaveDoubleBonus:
            levelItem.can_have_double_bonus,
          nextLevelSum: levelItem.next_level_sum,
          nextLevelLeft: levelItem.next_level_left,
          nextLevelTitle: levelItem.next_level_title,
          subtext: levelItem.subtext,
          text: levelItem.text
        }
      };
    },
    {} as BonusProgramLevelsMap
  );
};
