import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  getRedirectStatus,
  getScreen,
  getView
} from '../../../store/screen/selectors';
import { screensViewsMap } from '../../../consts/screensComponentsMap';
import { ScreenView } from '../../../types/screens';
import { RedirectLoader } from '../../elements/RedirectLoader';

export const AuthorizationModal = ({
  onClose
}: {
  onClose?: () => void;
}) => {
  const screen = useSelector(getScreen);
  const view = useSelector(getView);
  const isOnRedirect = useSelector(getRedirectStatus);

  const CurrentAuthComponent = useMemo(() => {
    if (
      view === null ||
      view === ScreenView.Form ||
      screen === null
    ) {
      return null;
    }

    const currentComponentsMap = screensViewsMap[view];

    return currentComponentsMap[screen];
  }, [view, screen]);

  if (CurrentAuthComponent === null) {
    return null;
  }

  return (
    <>
      <CurrentAuthComponent onClose={onClose} />
      {isOnRedirect && <RedirectLoader />}
    </>
  );
};
