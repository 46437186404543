import { createAsyncThunk } from '@reduxjs/toolkit';
import { SBER_ID_LINK } from '../../../config';
import type { TRootState } from '../../rootReducer';
import { getFallbackInsteadSberId } from '../../handbook';
import { formsStoreName } from '../consts';
import { submitPhoneForm } from './submitPhoneForm';

// TODO: требуется реализовать генерацию oidcParams на хосте
export const sberIdLogin = createAsyncThunk(
  formsStoreName + '/sberIdLogin',
  async ({ phone }: { phone: string }, thunkAPI) => {
    const storeState = thunkAPI.getState() as TRootState;
    const fallbackInsteadSberId =
      getFallbackInsteadSberId(storeState);

    if (!fallbackInsteadSberId) {
      try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const urls = await window.SberidSDK.getUrl(
          window.createSberidSDK.getAuthUrl(SBER_ID_LINK),
          { ...window.BX.oidcParams, login_hint: phone },
          {
            display: 'page',
            universalLinkUrl:
              window.createSberidSDK.getAuthUrl(
                SBER_ID_LINK
              )
          }
        );

        // Если нужно будет вернуть модалку, нужно заюзать
        // thunkAPI.dispatch(openSberSDKDialog(urls)))
        // из import { openSberSDKDialog } from './openSberSDKDialog';
        window.location.href = urls.link;
      } catch (sdkError) {
        console.error(
          'Error interacting with SberidSDK',
          sdkError
        );
        thunkAPI.dispatch(submitPhoneForm());
      }
    } else {
      thunkAPI.dispatch(submitPhoneForm());
    }
  }
);
