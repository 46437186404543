export const getUTMValue = (
  searchParams: URLSearchParams,
  paramKey: string
) => {
  const utmValue = searchParams.getAll(paramKey);

  if (utmValue.length === 1) {
    return utmValue[0];
  }

  return utmValue || '';
};

export const getUTMPropsFromQuery = (
  searchParams: URLSearchParams
) => {
  const searchParamsKeys = searchParams.keys();
  const utmPropsKeys = [...searchParamsKeys].filter(
    (paramKey) => paramKey.includes('utm')
  );

  if (utmPropsKeys.length) {
    return {
      isUTMAvailable: true,
      utmProps: utmPropsKeys.reduce(
        (metaResult, paramKey) => ({
          ...metaResult,
          [paramKey]: getUTMValue(searchParams, paramKey)
        }),
        {}
      )
    };
  }

  return { isUTMAvailable: false };
};
