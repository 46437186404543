import { createAsyncThunk } from '@reduxjs/toolkit';
import { errorsMap } from '../../consts/errors';
import { ErrorCode } from '../../types/errors';
import { LoginFlow } from '../../types/flows';
import type { TRootState } from '../rootReducer';
import { phoneAuthFormValidator } from './validators/phoneAuthForm';
import { emailAuthFormValidator } from './validators/emailAuthForm';
import { passwordResetFormValidator } from './validators/passwordResetForm';
import { sberIdAuthFromValidation } from './validators/sberIdAuthFrom';
import { passwordConfirmFormValidator } from './validators/passwordConfirmForm';
import { loginUser } from './thunks/loginUser';
import { submitPhoneForm } from './thunks/submitPhoneForm';
import { submitResetPassword } from './thunks/submitResetPassword';
import { sberIdLogin } from './thunks/sberIdLogin';
import { submitPasswordConfirm } from './thunks/submitPasswordConfirm';
import {
  getEmail,
  getPassword,
  getPasswordConfirm,
  getPhone
} from './selectors';
import type {
  EmailAuthFormUpdateProps,
  PasswordAuthFormUpdateProps,
  ResetPasswordFormUpdateProps,
  ConfirmPasswordFormUpdateProps
} from './types';
import { formsStoreName } from './consts';
import { formsActions } from './slice';

export const setAuthFormField = createAsyncThunk(
  formsStoreName + '/setAuthFormField',
  async (
    { field, value }: EmailAuthFormUpdateProps,
    thunkAPI
  ) => {
    const store = thunkAPI.getState() as TRootState;

    thunkAPI.dispatch(
      formsActions.setField({ field, value })
    );

    thunkAPI.dispatch(
      formsActions.updateErrorsList(
        await emailAuthFormValidator({
          email: getEmail(store),
          password: getPassword(store),
          [field]: value
        })
      )
    );
  }
);

export const setAuthPhoneFormField = createAsyncThunk(
  formsStoreName + '/setAuthPhoneFormField',
  async (
    { field, value }: PasswordAuthFormUpdateProps,
    thunkAPI
  ) => {
    thunkAPI.dispatch(
      formsActions.setField({ field, value })
    );

    thunkAPI.dispatch(
      formsActions.updateErrorsList(
        await phoneAuthFormValidator({
          phone: value
        })
      )
    );
  }
);

export const setResetEmailFormField = createAsyncThunk(
  formsStoreName + '/setResetEmailFormField',
  async (
    { field, value }: ResetPasswordFormUpdateProps,
    thunkAPI
  ) => {
    thunkAPI.dispatch(
      formsActions.setField({ field, value })
    );

    thunkAPI.dispatch(
      formsActions.updateErrorsList(
        await passwordResetFormValidator({
          email: value
        })
      )
    );
  }
);

export const setPasswordConfirmFormField = createAsyncThunk(
  formsStoreName + '/setPasswordConfirmFormField',
  async (
    { field, value }: ConfirmPasswordFormUpdateProps,
    thunkAPI
  ) => {
    const store = thunkAPI.getState() as TRootState;

    thunkAPI.dispatch(
      formsActions.setField({ field, value })
    );

    thunkAPI.dispatch(
      formsActions.updateErrorsList(
        await passwordConfirmFormValidator({
          password: getPassword(store),
          passwordConfirm: getPasswordConfirm(store),
          [field]: value
        })
      )
    );
  }
);

export const setSberIdFormField = createAsyncThunk(
  formsStoreName + '/setSberIdFormField',
  async (
    { field, value }: PasswordAuthFormUpdateProps,
    thunkAPI
  ) => {
    thunkAPI.dispatch(
      formsActions.setField({ field, value })
    );

    thunkAPI.dispatch(
      formsActions.updateErrorsList(
        await sberIdAuthFromValidation({
          phone: value
        })
      )
    );
  }
);

export const submitEmailAuthForm = createAsyncThunk(
  formsStoreName + '/submitEmailAuthForm',
  async (_, thunkAPI) => {
    const store = thunkAPI.getState() as TRootState;
    const email = getEmail(store);
    const password = getPassword(store);

    const errorsList = await emailAuthFormValidator({
      email,
      password
    });

    if (!errorsList.length) {
      thunkAPI.dispatch(
        loginUser({
          email,
          password,
          flow: LoginFlow.email,
          errors: [
            errorsMap[ErrorCode.emailAuthRequestError]
          ]
        })
      );
    } else {
      thunkAPI.dispatch(
        formsActions.updateErrorsList(errorsList)
      );
    }
  }
);

export const submitCodeForm = createAsyncThunk(
  formsStoreName + '/submitCodeForm',
  async ({ code }: { code: string }, thunkAPI) => {
    const store = thunkAPI.getState() as TRootState;
    const phone = getPhone(store);

    thunkAPI.dispatch(
      loginUser({
        phone,
        code,
        flow: LoginFlow.phone,
        errors: [
          errorsMap[ErrorCode.invalidCodeRequestError]
        ]
      })
    );
  }
);

export const submitSberIdForm = createAsyncThunk(
  formsStoreName + '/submitSberIdForm',
  async (_, thunkAPI) => {
    const store = thunkAPI.getState() as TRootState;
    const phone = getPhone(store);

    thunkAPI.dispatch(
      sberIdLogin({
        phone
      })
    );
  }
);

export const clearErrors = createAsyncThunk(
  formsStoreName + '/clearErrors',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(formsActions.updateErrorsList([]));
  }
);

export {
  submitPhoneForm,
  submitResetPassword,
  submitPasswordConfirm
};
