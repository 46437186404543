enum EaptekaDomens {
  'release' = 'release.eapteka.ru',
  'prod' = 'eapteka.ru',
  'prodWww' = 'www.eapteka.ru'
}

const API_URLS_MAP: Record<string, string> = {
  [EaptekaDomens.release]:
    'https://apirelease.eapteka.ru/api/v3',
  [EaptekaDomens.prod]: 'https://api.eapteka.ru/api/v3',
  [EaptekaDomens.prodWww]: 'https://api.eapteka.ru/api/v3'
};

export const ISOLATE_API_URL = (() => {
  if (typeof location !== 'undefined') {
    if (location.hostname.includes('stage')) {
      return API_URLS_MAP[EaptekaDomens.release];
    }

    if (location.hostname in API_URLS_MAP) {
      return API_URLS_MAP[location.hostname];
    }
  }

  return API_URLS_MAP[EaptekaDomens.prod];
})();
