// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xOXrQfc{margin-bottom:15px}.xOXrQfc a.sbid-button{position:relative;display:inline-flex;justify-content:center;align-items:center;width:100%;height:44px;min-height:28px;max-height:64px;cursor:pointer;text-decoration:none;color:#fff;border-color:#8168f0;border-radius:22px;background:#8168f0}.xOXrQfc .sbid-button-loader{width:100%;height:44px;min-height:28px;max-height:64px;cursor:pointer;text-decoration:none;color:#fff;border-color:#8168f0;border-radius:22px;background:#8168f0}.VSFX0NB a.sbid-button{pointer-events:none;opacity:.4}.Wm5Ub_A{position:absolute;top:0;visibility:hidden}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `xOXrQfc`,
	"buttonDisabled": `VSFX0NB`,
	"buttonHide": `Wm5Ub_A`
};
export default ___CSS_LOADER_EXPORT___;
