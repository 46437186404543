// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bxJYhFF[type=button]{width:100%;padding:0;font-size:14px;line-height:16px;text-align:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formLink": `bxJYhFF`
};
export default ___CSS_LOADER_EXPORT___;
