import React, { FormEvent, VFC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  formsActions,
  getRequestState,
  isPhoneAuthFormSubmitDisabled,
  setAuthPhoneFormField,
  submitPhoneForm
} from '../../../store/forms';
import { FormFields } from '../../../types/form';
import { FormWrapper } from '../../elements/FormWrapper';
import { PolicyBlock } from '../../elements/PolicyBlock';
import { FormGroup } from '../../elements/FormGroup';
import { SubmitFormButton } from '../../elements/SubmitFormButton';
import { PhoneField } from '../../elements/PhoneField';
import { AuthorizationPhoneFormProps } from './types';

export const AuthorizationPhoneForm: VFC<
  AuthorizationPhoneFormProps
> = ({ sberIDBlock, changeFlowBlock, onClose }) => {
  const dispatch = useDispatch();
  const isRequestInProgress = useSelector(getRequestState);
  const isSubmitDisable = useSelector(
    isPhoneAuthFormSubmitDisabled
  );

  const submitHandler = (event: FormEvent) => {
    event.preventDefault();

    dispatch(submitPhoneForm());
  };

  useEffect(() => {
    return () => {
      dispatch(
        formsActions.setSmsLastRequestTimestamp(null)
      );
    };
  }, [dispatch]);

  return (
    <FormWrapper
      title="Вход или регистрация"
      onClose={onClose}
    >
      <form onSubmit={submitHandler}>
        <PhoneField
          label="Телефон"
          field={FormFields.phone}
          setter={setAuthPhoneFormField}
        />
        <PolicyBlock />
        <FormGroup>
          <SubmitFormButton
            disabled={isSubmitDisable}
            isRequestInProgress={isRequestInProgress}
          >
            Получить код по СМС
          </SubmitFormButton>
          {sberIDBlock}
        </FormGroup>
        {changeFlowBlock}
      </form>
    </FormWrapper>
  );
};
