import Cookies from 'js-cookie';
import { DeepLinkTypes } from '../../types/sberId';
import { COOKIE_UTM_KEY } from '../../config';
import {
  isAndroid,
  isChrome,
  isMozilla,
  isOpera,
  isSamsung
} from '../browserCheck';
import { buildUrlsWithParams } from '../buildUrlsWithParams';
import { getUTMPropsFromQuery } from './getUTMProps';

export const createSbUniversalLink = (
  deepLinkValue: DeepLinkTypes,
  searchParams: URLSearchParams
) => {
  const { isUTMAvailable, utmProps } =
    getUTMPropsFromQuery(searchParams);

  const { BX, SberidSDK } = window;
  const customParams = BX.oidcParams;

  if (isUTMAvailable) {
    Cookies.set(COOKIE_UTM_KEY, JSON.stringify(utmProps));
  }

  const sbSDK = new SberidSDK({
    oidc: customParams,
    utmProxyDisabled: false,
    mweb2app: true,
    ...(DeepLinkTypes.authBySberIDWeb
      ? {
          baseUrl:
            'https://id.sber.ru/CSAFront/oidc/authorize.do',
          // TODO: проверить, что действительно так
          // https://developers.sber.ru/docs/ru/sberid/sdk/javascript/configuration#parametry-dlya-generatsii-universalnyh-ssylok
          // https://developers.sber.ru/docs/ru/sberid/faq/a4-switching-to-cloud#pri-ispolzovanii-universalnyh-ssylok
          // universalLink: 'https://id.sber.ru/CSAFront/oidc/sberbank_id/authorize.do',
          universalLinkUrl:
            'https://id.sber.ru/CSAFront/oidc/sberbank_id/authorize.do'
        }
      : {})
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const sbUniversalLink = sbSDK.universalLinkDetect.config;

  if (isAndroid && isChrome) {
    customParams.package = 'com.android.chrome';
  }

  if (isAndroid && isMozilla) {
    customParams.package = 'org.mozilla.firefox';
  }

  if (isAndroid && isOpera) {
    customParams.package = 'com.opera.browser';
  }

  if (isAndroid && isSamsung) {
    customParams.package = 'com.sec.android.app.sbrowser';
  }

  if (deepLinkValue === DeepLinkTypes.authBySberIDApp) {
    return buildUrlsWithParams(
      sbUniversalLink.deeplinkUrl,
      customParams
    );
  }

  if (deepLinkValue === DeepLinkTypes.authBySberIDWeb) {
    return buildUrlsWithParams(
      sbUniversalLink.universalLinkUrl,
      customParams
    );
  }

  return false;
};
