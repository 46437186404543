export enum ScreenCode {
  emailEnter = 'emailEnter',
  passwordRecovery = 'passwordRecovery',
  setPassword = 'setPassword',
  passwordRecoverySuccess = 'passwordRecoverySuccess',
  codeEnter = 'codeEnter',
  phoneEnter = 'phoneEnter',
  sberIdPhoneEnter = 'sberIdPhoneEnter'
}

export enum ScreenView {
  Form = 'Form',
  Modal = 'Modal'
}
