import { dataLayerPush } from './dataLayer';
import {
  SendAmplitudeAnalyticsEventProps,
  AmplitudeAnalyticsEvent
} from './types';

export const sendAmplitudeAnalyticsEvent = ({
  id,
  name,
  customParams = {}
}: SendAmplitudeAnalyticsEventProps) => {
  if (id && name) {
    const params: AmplitudeAnalyticsEvent = {
      event: 'addEvents_Amplitude',
      event_id: id,
      event_name: name
    };

    if (customParams) {
      for (const [key, value] of Object.entries(
        customParams
      )) {
        params[key as string] =
          typeof value === 'string'
            ? value
            : JSON.stringify(value);
      }
    }
    dataLayerPush(params);
  }
};
