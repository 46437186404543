import { ErrorCode } from '../../types/errors';
import { errorsMap } from '../../consts/errors';
import { validatorsGenerator } from './validatorsGenerator';

const emailRegExp =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validateEmail = async (email: string) =>
  validatorsGenerator([
    {
      condition: async () => !emailRegExp.test(email),
      error: errorsMap[ErrorCode.invalidEmail]
    }
  ]);
