// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.n9jCT0n{position:fixed;top:0;left:0;display:flex;justify-content:center;align-items:center;width:100%;height:100%;opacity:.75;background:#fff;z-index:1400}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"redirectLoader": `n9jCT0n`
};
export default ___CSS_LOADER_EXPORT___;
