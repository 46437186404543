const setCookie = (name: string, value: string, days: number) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

const isLocalStorageAvailable = () => {
  try {
    const storage = window['localStorage'],
      x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
};

export const setBackUrl = (href: string) => {
  const backUrlStorageKey = 'toUrl';
  if (isLocalStorageAvailable()) {
    localStorage.setItem(backUrlStorageKey, href);
  } else {
    setCookie(backUrlStorageKey, href, 1);
  }
};

setBackUrl(window.location.href);
