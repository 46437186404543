import React, { type MouseEvent, type VFC } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  ButtonSizes,
  Icon
} from '@npm-registry/eapteka-ui';
import { ButtonTags } from '@npm-registry/eapteka-ui/lib/components/Button/Button';
import { screenActions } from '../../../store/screen';
import { ScreenCode } from '../../../types/screens';
import Styles from './SberIdButton.scss';

export interface SberIdButtonProps {
  disabled?: boolean;
}

export const SberIdButton: VFC<SberIdButtonProps> = ({
  disabled = false
}) => {
  const dispatch = useDispatch();

  const sberAuthClickHandler = (
    event: MouseEvent<HTMLAnchorElement>
  ) => {
    event.preventDefault();

    dispatch(
      screenActions.changeScreen(
        ScreenCode.sberIdPhoneEnter
      )
    );
  };

  return (
    <Button
      className={Styles.sberIdButton}
      tag={ButtonTags.a}
      size={ButtonSizes.s}
      href={'#'}
      onClick={sberAuthClickHandler}
      disabled={disabled}
    >
      <Icon name="sberbank" /> Войти по Сбер ID
    </Button>
  );
};
