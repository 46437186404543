import type {
  SberBonusesResponse,
  BonusInfoResponse
} from '../types/sberBonuses';
import { API_URLS_LIST } from '../config';
import { baseMapResponse } from './auth';
import { apiService } from './apiService';

export const sberBonusesRequest =
  apiService.createGetMethod<
    undefined,
    Promise<SberBonusesResponse>
  >(API_URLS_LIST.getSberBonuses, {
    mapResponse: baseMapResponse
  });

export const sberBonusesInfoRequest =
  apiService.createGetMethod<
    undefined,
    Promise<BonusInfoResponse>
  >(API_URLS_LIST.getSberBonusesInfo, {
    mapResponse: baseMapResponse
  });
