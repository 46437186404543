// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button.eKPdW_k{width:100%;font-family:var(--control-font-family);font-size:16px;font-weight:600;line-height:24px;color:#333f48;background:#f6f7fa}button.eKPdW_k:hover{color:#333f48}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `eKPdW_k`
};
export default ___CSS_LOADER_EXPORT___;
