import React, { VFC } from 'react';
import InputMask, { Props } from 'react-input-mask';
import { useFromField } from '../../../hooks/useFromField';
import {
  BaseFieldProps,
  InputTypes
} from '../../../types/form';
import { FormGroup } from '../FormGroup';
import { LabeledInput } from '../LabeledInput';

export const PhoneField: VFC<BaseFieldProps> = ({
  label,
  field,
  setter
}) => {
  const { value, onChange, error } = useFromField({
    field,
    setter
  });

  return (
    <FormGroup>
      <InputMask
        value={value}
        onChange={onChange}
        mask="+7 (999) 999-99-99"
      >
        {(customProps: Props) => (
          <LabeledInput
            {...customProps}
            error={error}
            value={customProps.value as string}
            label={label}
            customType={InputTypes.tel}
            onChange={customProps.onChange}
          />
        )}
      </InputMask>
    </FormGroup>
  );
};
