import React, { memo } from 'react';
import Styles from './Preloader.scss';

export const Preloader = memo(() => {
  return (
    <svg
      className={Styles.preloader}
      width="64"
      height="64"
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_6915_102796)">
        <path
          className={Styles.path}
          d="M55.5374 20.2238H56.7303C60.7635 20.2238 64.0394 23.4939 64.0394 27.5201V36.4798C64.0394 40.506 60.7635 43.7761 56.7303 43.7761H47.149C44.8768 43.7761 43.7975 44.9291 43.7975 47.1218V56.6864C43.7975 60.7125 40.5216 63.9826 36.4884 63.9826H27.5131C23.4798 63.9826 20.204 60.7125 20.204 56.6864C20.204 56.6864 20.204 49.5224 20.204 47.1218C20.204 44.7401 19.03 43.7761 16.8335 43.7761H7.30904C3.27582 43.7572 0 40.4871 0 36.4609V27.5012C0 23.475 3.27582 20.2049 7.30904 20.2049H16.8714C18.9922 20.2049 20.2419 19.014 20.2419 16.8025V7.3135C20.2419 3.28731 23.5177 0.0172119 27.5509 0.0172119H36.5263C40.5595 0.0172119 43.8353 3.28731 43.8353 7.3135V16.8214C43.8353 19.1464 44.6874 20.2238 47.1301 20.2238H48.8153L43.8921 26.9341C40.124 26.6317 37.1133 23.475 37.1133 19.6378V10.111C37.1133 7.78606 36.2233 6.72753 33.7239 6.72753H30.3155C28.1947 6.72753 26.9639 7.86167 26.9639 10.111V19.6378C26.9639 23.664 23.6881 26.9341 19.6549 26.9341H10.1115C7.64988 26.9341 6.72205 28.5408 6.72205 30.3365V33.7011C6.72205 35.7237 7.95285 37.0657 10.0736 37.0657H19.6549C23.6881 37.0657 26.9639 40.3358 26.9639 44.362V53.9077C26.9639 56.0626 28.0433 57.2723 30.2776 57.2723H33.7049C35.8257 57.2723 37.0943 55.987 37.0943 53.8699V44.362C37.0943 40.3358 40.3702 37.0657 44.4034 37.0657H53.9847C56.2948 37.0657 57.3362 35.8938 57.3362 33.6633V30.2987C57.3362 27.9926 56.1244 26.9341 53.9089 26.9341H50.6142L55.5374 20.2238Z"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_6915_102796"
          x1="25.4434"
          y1="77.9487"
          x2="57.6644"
          y2="11.4843"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF00D5" />
          <stop offset="0.187441" stop-color="#D438A2" />
          <stop offset="0.316217" stop-color="#B240E5" />
          <stop offset="0.541913" stop-color="#8168F0" />
          <stop offset="0.692074" stop-color="#8168F0" />
          <stop offset="0.9906" stop-color="#87DEFC" />
        </linearGradient>
        <clipPath id="clip0_6915_102796">
          <rect width="64" height="64" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
});
