import Cookies from 'js-cookie';

export const saveRecipe = (url: string) => {
  const string = decodeURIComponent(url);

  function createArrayRecipes(string: string) {
    const arrayRecipe = string
      .split('&')
      .map((item) => (item.includes('recipe') ? item : ''))
      .filter(Boolean);

    return {
      data: arrayRecipe.map((item) => ({
        provider: item.substring(
          item.indexOf('[') + 1,
          item.indexOf(']')
        ),
        recipe_id: item.split('=')[1]
      }))
    };
  }

  function getDomainOption(url: string) {
    let parts = url.split('.');

    if (parts.length > 2) {
      parts = parts.slice(-2);
    }

    return '.' + parts.join('.');
  }

  Cookies.set(
    'recipe',
    JSON.stringify(createArrayRecipes(string)),
    { domain: getDomainOption(window.location.host) }
  );
};
