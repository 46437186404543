import type { ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getErrorMessageByType,
  getFormField
} from '../store/forms';
import type {
  FieldSetter,
  FormFieldsInStore
} from '../types/form';

export interface UseFromFieldProps {
  field: FormFieldsInStore;
  setter: FieldSetter;
}

export const useFromField = ({
  field,
  setter
}: UseFromFieldProps) => {
  const dispatch = useDispatch();
  const value = useSelector(getFormField(field));
  const error = useSelector(getErrorMessageByType(field));

  const onChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(
      setter({
        field,
        value: event.target.value
      })
    );
  };

  return { value, onChange, error };
};
