import React, { FC } from 'react';
import { Link, LinkTags } from '@npm-registry/eapteka-ui';
import Styles from './FormLink.scss';

export interface FormLink {
  onClick: () => void;
}

export const FormLink: FC<FormLink> = ({
  onClick,
  children
}) => (
  <Link
    className={Styles.formLink}
    tag={LinkTags.button}
    onClick={onClick}
  >
    {children}
  </Link>
);
