import React from 'react';
import {
  Portal,
  Preloader,
  PreloaderSizes
} from '@npm-registry/eapteka-ui';
import Styles from './RedirectLoader.scss';

export const RedirectLoader = () => (
  <Portal visible>
    <div className={Styles.redirectLoader}>
      <Preloader
        size={
          PreloaderSizes.l as keyof typeof PreloaderSizes
        }
      />
    </div>
  </Portal>
);
