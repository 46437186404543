import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import {
  closeModal,
  screenActions
} from '../../../store/screen';
import { ScreenCode } from '../../../types/screens';
import { AuthorizationPhoneForm } from '../AuthorizationPhoneForm';
import { ModalWrapper } from '../../elements/ModalWrapper';
import { FormGroup } from '../../elements/FormGroup';
import { Divider } from '../../elements/Divider';
import { FormLink } from '../../elements/FormLink';

export const AuthorizationPhoneModal: FC<{
  onClose?: () => void;
}> = ({ onClose }) => {
  const dispatch = useDispatch();

  const emailAuthClickHandler = () => {
    dispatch(
      screenActions.changeScreen(ScreenCode.emailEnter)
    );
  };

  const closeHandler = () => {
    if (onClose) {
      onClose();
      return;
    }
    dispatch(closeModal());
  };

  return (
    <ModalWrapper onClose={closeHandler}>
      <AuthorizationPhoneForm
        onClose={closeHandler}
        sberIDBlock={<Divider>ИЛИ</Divider>}
        changeFlowBlock={
          <FormGroup>
            <FormLink onClick={emailAuthClickHandler}>
              Войти по почте
            </FormLink>
          </FormGroup>
        }
      />
    </ModalWrapper>
  );
};
