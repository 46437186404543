// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Dt29Fgi{position:absolute;top:0;left:0;width:100%;height:100%}@media(max-width: 768px){.Dt29Fgi{display:none}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"background": `Dt29Fgi`
};
export default ___CSS_LOADER_EXPORT___;
