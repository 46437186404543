import { createSelector } from '@reduxjs/toolkit';
import type {
  ScreenCode,
  ScreenView
} from '../../types/screens';
import type { TRootState } from '../rootReducer';
import type { ScreenStore } from './types';

export const getScreenState = (
  state: TRootState
): ScreenStore => state.screen;

export const getScreen: (
  state: TRootState
) => ScreenCode | null = createSelector(
  getScreenState,
  (formsStore) => formsStore.screen
);

export const getView: (
  state: TRootState
) => ScreenView | null = createSelector(
  getScreenState,
  (formsStore) => formsStore.view
);

export const getRedirectStatus: (
  state: TRootState
) => boolean = createSelector(
  getScreenState,
  (formsStore) => formsStore.isOnRedirect
);
