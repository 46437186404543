import type {
  CartToken,
  UserToken
} from '../../types/tokens';

interface LoginByUserTokenProps {
  USER_TOKEN: UserToken;
  CART_TOKEN: CartToken;
}

export const loginByUserToken = async ({
  USER_TOKEN,
  CART_TOKEN
}: LoginByUserTokenProps): Promise<{
  processAuth: boolean;
} | null> => {
  if (window?.BX) {
    return await new Promise(function (resolve) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      new BX.Ajax2.Request({
        handler: 'loginByUserToken',
        data: {
          USER_TOKEN,
          CART_TOKEN
        }
      })
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .on('response', function (response) {
          resolve(response);
          document.dispatchEvent(
            new Event('a5.login.success')
          );
        })
        .send();
    });
  }

  return null;
};
