import React, { VFC } from 'react';
import { Provider } from 'react-redux';
import { StoreRegistry } from '../../store';
import { AuthorizationButton } from '../../components/blocks/AuthorizationButton';
import { AuthorizationButtonProps } from '../../components/blocks/AuthorizationButton/types';

export const AuthorizationButtonEntryPoint: VFC<
  AuthorizationButtonProps
> = (props) => (
  <Provider store={StoreRegistry.instance.store}>
    <AuthorizationButton {...props} />
  </Provider>
);
