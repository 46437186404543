// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.XKbWUaK{border-radius:18px}.OSUflil{fill:url("#paint0_linear_6915_102796");stroke-width:16px;stroke-dasharray:406;stroke-dashoffset:406;animation:uOfxSCJ 3s linear infinite;stroke:#fff;stroke-linecap:round}@keyframes uOfxSCJ{0%{stroke-dashoffset:406}40%{stroke-dashoffset:192}50%{stroke-dashoffset:192}90%{stroke-dashoffset:406}100%{stroke-dashoffset:406}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"preloader": `XKbWUaK`,
	"path": `OSUflil`,
	"draw": `uOfxSCJ`
};
export default ___CSS_LOADER_EXPORT___;
