// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BZT_qKS{padding-right:48px}.WiUaljh[type=button]{--button-s-size: 48px;position:absolute;top:0;right:3px;display:flex;justify-content:center;align-items:center;width:48px;height:48px;margin:0;padding:0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"passwordField": `BZT_qKS`,
	"passwordFieldToggle": `WiUaljh`
};
export default ___CSS_LOADER_EXPORT___;
