import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ScreenCode,
  ScreenView
} from '../../../types/screens';
import { formsStoreName } from '../../forms/consts';
import type { TRootState } from '../../rootReducer';
import {
  getAuthPrioritizeSberId,
  getHandbookReceivedState
} from '../../handbook';
import { getView, getScreen } from '../selectors';
import { screenActions } from '../slice';

interface ShowStartScreenProps {
  description?: string;
  screen?: ScreenCode;
  view?: ScreenView;
}

export const showStartScreen = createAsyncThunk(
  formsStoreName + '/showStartScreen',
  async (
    { description, view, screen }: ShowStartScreenProps,
    thunkAPI
  ) => {
    const store = thunkAPI.getState() as TRootState;
    const isHandbookReceived =
      getHandbookReceivedState(store);

    if (!isHandbookReceived) {
      return;
    }

    const authPrioritizeSberId =
      getAuthPrioritizeSberId(store);

    const currentView = getView(store);
    const currentScreen = getScreen(store);

    if (
      currentView === ScreenView.Form &&
      currentScreen !== null
    ) {
      return;
    }

    const defaultScreen = authPrioritizeSberId
      ? ScreenCode.sberIdPhoneEnter
      : ScreenCode.phoneEnter;

    thunkAPI.dispatch(
      screenActions.changeScreen(
        screen && screen in ScreenCode
          ? screen
          : defaultScreen
      )
    );

    if (view && view in ScreenView) {
      thunkAPI.dispatch(screenActions.setView(view));
    }
    if (typeof description === 'string') {
      thunkAPI.dispatch(
        screenActions.setDescription(description)
      );
    }
  }
);
