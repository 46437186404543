import React, { VFC } from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from '../../../store/screen';
import { ModalWrapper } from '../../elements/ModalWrapper';
import { ResetPasswordSuccessForm } from '../ResetPasswordSuccessForm';

export interface ResetPasswordSuccessModalProps {
  onClose?: () => void;
}

export const ResetPasswordSuccessModal: VFC<
  ResetPasswordSuccessModalProps
> = ({ onClose }) => {
  const dispatch = useDispatch();

  const closeHandler = () => {
    if (onClose) {
      onClose();
      return;
    }
    dispatch(closeModal());
  };

  return (
    <ModalWrapper onClose={closeHandler}>
      <ResetPasswordSuccessForm onClose={closeHandler} />
    </ModalWrapper>
  );
};
