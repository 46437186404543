export type AnalyticsEventValue =
  | string
  | number
  | boolean
  | null
  | undefined;

export type AnalyticsEventParams = Record<
  string,
  AnalyticsEventValue
>;
export interface SendAmplitudeAnalyticsEventProps {
  id: string;
  name: string;
  customParams: AnalyticsEventParams;
}

export interface AmplitudeAnalyticsEvent
  extends AnalyticsEventParams {
  event: 'addEvents_Amplitude';
  event_id: string;
  event_name: string;
}

export enum AnalyticsEvents {
  loginSuccessful = 'loginSuccessful',
  loginUnsuccessful = 'loginUnsuccessful',
  userIdSet = 'userIdSet'
}
