import { createSelector } from '@reduxjs/toolkit';
import type { User } from '../../types/user';
import type { TRootState } from '../rootReducer';
import type { UserStore } from './types';

export const getUserState = (
  state: TRootState
): UserStore => state.user;

export const getUser: (state: TRootState) => User =
  createSelector(
    getUserState,
    (userStore) => userStore.user
  );

export const getUserAuthState: (
  state: TRootState
) => boolean = createSelector(
  getUserState,
  (userStore) => userStore.isUserAuth
);
