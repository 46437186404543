import React, { FC } from 'react';
import {
  Button,
  ButtonThemes,
  Icon,
  ITitleTags,
  Title,
  TitleTags
} from '@npm-registry/eapteka-ui';
import { ModalWrapperProps } from './types';
import Styles from './FormWrapper.scss';

export const FormWrapper: FC<ModalWrapperProps> = ({
  title,
  onClose,
  children
}) => (
  <div className={Styles.formWrapper}>
    <div className={Styles.formWrapperHead}>
      {onClose && (
        <Button
          className={Styles.formWrapperClose}
          theme={ButtonThemes.transparent}
          onClick={onClose}
        >
          <Icon name="close" />
        </Button>
      )}
      <div className={Styles.formWrapperContainer}>
        <Title
          className={Styles.formWrapperTitle}
          tag={TitleTags.h2 as ITitleTags}
        >
          {title}
        </Title>
      </div>
    </div>
    <div className={Styles.formWrapper}>
      <div className={Styles.formWrapperContainer}>
        {children}
      </div>
    </div>
  </div>
);
