import {
  LoginFlow,
  SberFlowInitiator
} from '../../types/flows';

export const sberIdStartMessagesMap = {
  [SberFlowInitiator.ssoApp]: 'ssoAppFirstPage',
  [SberFlowInitiator.ssoWeb]: 'ssoAppFirstPage',
  [SberFlowInitiator.authBtn]: 'ssoAppFirstPage'
};

export const sendSberIdStartAnalyticEvent = (
  type: SberFlowInitiator
) => {
  const timeFirstAuth = new Date().getTime() / 1000;

  localStorage.setItem(
    'timeFirstAuth',
    JSON.stringify(timeFirstAuth)
  );
  localStorage.setItem('sberid', type);
  localStorage.setItem('authBy', LoginFlow.sberid);

  if (window.ga) {
    window.ga(
      'send',
      'event',
      'Auth',
      sberIdStartMessagesMap[type]
    );
  }
};
