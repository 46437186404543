import React, { FC } from 'react';
import { Checkbox } from '@npm-registry/eapteka-ui';
import clsx from 'clsx';
import { CheckboxGroupProps } from './types';
import Styles from './CheckboxGroup.scss';

export const CheckboxGroup: FC<CheckboxGroupProps> = ({
  onChange,
  checked,
  children,
  error = false
}) => (
  <label
    className={clsx(Styles.checkboxGroup, {
      [Styles.checkboxGroupError]: error
    })}
  >
    <Checkbox onChange={onChange} checked={checked} />
    <span className={Styles.checkboxGroupLabel}>
      {children}
    </span>
  </label>
);
