import { initializeSberIdSDKPersonalizedButton } from '../shared/services/sberId/sberIdSDKPersonalizedButtonService';
import { UserEvents } from '../helpers/events/userEvents';
import { initAuthState } from './user/thunks/initAuthState';
import {
  getUser,
  getUserAuthState
} from './user/selectors';
import { logoutUser } from './user/actionCreators';
import { showStartScreen } from './screen/thunks/showStartScreen';
import type { TRootState } from './rootReducer';
import type { TStore } from './store';
import { getIsPersonalButtonAuthEnabled } from './handbook';

export const storeInitSideEffects = async (store: TStore) => {
  const dispatch = store.dispatch;


  try {
    await dispatch(initAuthState());

    const storeState = store.getState() as TRootState;
    const isUserAuth = getUserAuthState(storeState);
    const isPersonalButtonAuthEnabled = getIsPersonalButtonAuthEnabled(storeState);

    if (!isUserAuth && isPersonalButtonAuthEnabled) {
      initializeSberIdSDKPersonalizedButton(dispatch);
    }
  } catch (error) {
    console.error('Error during storeInitSideEffects:', error);
  }

  // Проверить сначала в монолите.
  document.addEventListener(
    'openAuthPopup',
    (event: CustomEvent<string>) => {
      dispatch(
        showStartScreen({
          description: event?.detail || ''
        })
      );
    }
  );

  document.addEventListener(
    UserEvents.userGetBroadcast,
    () => {
      document.dispatchEvent(
        new CustomEvent(UserEvents.userSetBroadcast, {
          detail: getUser(store.getState() as TRootState)
        })
      );
    }
  );

  document.addEventListener(
    UserEvents.userGetAuthStateRequest,
    () => {
      document.dispatchEvent(
        new CustomEvent(
          UserEvents.userGetAuthStateResponse,
          {
            detail: {
              isAuthorized: getUserAuthState(store.getState() as TRootState)
            }
          }
        )
      );
    }
  );

  document.addEventListener(UserEvents.userLogout, () => {
    dispatch(logoutUser());
  });
};
