// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../assets/icons/info.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PPuU4KP{display:flex;margin-bottom:24px;padding:16px 20px 16px 60px;font-size:14px;font-weight:normal;font-style:normal;line-height:1.5;text-align:left;color:#333f48;border-radius:8px;background:url(${___CSS_LOADER_URL_REPLACEMENT_0___}) 20px center no-repeat #f6f7fa}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"info": `PPuU4KP`
};
export default ___CSS_LOADER_EXPORT___;
