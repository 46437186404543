import {
  createSlice,
  type PayloadAction
} from '@reduxjs/toolkit';
import type { Handbook } from '../../types/handbook';
import type { BonusInfo } from '../../types/sberBonuses';
import type { HandbookStore } from './types';
import { handbookStoreName } from './consts';

export const getInitialState = (): HandbookStore => ({
  features: null,
  bonusInfo: null,
  isFeaturesRequestReceive: false
});

const initialState = getInitialState();

export const handbookSlice = createSlice({
  name: handbookStoreName,
  initialState,
  reducers: {
    setHandbook: (
      state,
      { payload }: PayloadAction<Handbook>
    ) => {
      state.features = payload;
    },
    setBonusInfo: (
      state,
      { payload }: PayloadAction<BonusInfo>
    ) => {
      state.bonusInfo = payload;
    },
    setRequestState: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFeaturesRequestReceive = payload;
    },
  }
});

const handbookReducer = handbookSlice.reducer;

const handbookAction = handbookSlice.actions;

export { handbookReducer, handbookAction, initialState };
