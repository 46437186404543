import Cookies from 'js-cookie';

export const removeTokensFromStorage = (type = '') => {
  if (type) {
    localStorage.removItem(type);
  } else {
    Cookies.remove('user-token');
    Cookies.remove('cart-token');
    localStorage.removeItem('user-token');
    localStorage.removeItem('cart-token');
    localStorage.removeItem('authBy');
    localStorage.removeItem('NAME');
    localStorage.removeItem('PHONE');
    localStorage.removeItem('search_history');
  }
};
