import React, { type VFC } from 'react';
import {
  Button,
  ButtonSizes,
  ButtonThemes,
  Icon,
  type ITitleTags,
  Title,
  TitleTags
} from '@npm-registry/eapteka-ui';
import { AuthorizationSberIdFormHOCHeadProps } from './AuthorizationSberIdFormHOCHead.types';
import Styles from './AuthorizationSberIdFormHOCHead.scss';

export const AuthorizationSberIdFormHOCHead: VFC<
  AuthorizationSberIdFormHOCHeadProps
> = ({ onClose }) => {
  return (
    <div className={Styles.head}>
      <Title
        className={Styles.title}
        tag={TitleTags.h2 as ITitleTags}
      >
        Вход или регистрация
      </Title>
      {onClose && (
        <Button
          className={Styles.closeButton}
          theme={ButtonThemes.transparent}
          size={ButtonSizes.s}
          onClick={onClose}
        >
          <Icon name="close" />
        </Button>
      )}
    </div>
  );
};
