import React, { VFC } from 'react';
import { useDispatch } from 'react-redux';
import { showStartScreen } from '../../../store/screen/thunks/showStartScreen';
import { AuthorizationButtonProps } from './types';

export const AuthorizationButton: VFC<
  AuthorizationButtonProps
> = ({ className, ...formProps }) => {
  const dispatch = useDispatch();

  const onClickHandler = () => {
    dispatch(showStartScreen(formProps));
  };

  return (
    <button
      className={className}
      onClick={onClickHandler}
    />
  );
};
