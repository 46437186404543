import type { LoginRequestData } from '../types/api';
import type { AuthResponse } from '../types/user';
import { API_URLS_LIST } from '../config';
import { baseMapResponse } from './auth';
import { apiService } from './apiService';

export const getUserRequest = apiService.createGetMethod<
  LoginRequestData,
  Promise<AuthResponse>
>(API_URLS_LIST.getUser, {
  mapResponse: baseMapResponse
});
