export enum ClientStatuses {
  A = 'A',
  R = 'R',
  D = 'D',
  L = 'L'
}

export interface SberBonusesResponse {
  bonusBalance: {
    active: number;
    debt: number;
    inactive: number;
    total: number;
  };
  clientState: ClientStatuses;
  loyaltyAgreement: number;
}

export interface BonusInfoResponse {
  withdraw: {
    header: string;
    card: string;
    sberprime: string;
    limit: string;
  };
  earn: {
    header: string;
    exchange_bonus: string;
    exchange_rub: string;
    text: string;
  };
  earn_info: {
    min_order_sum: number;
    percent: number;
  }[];
  consent_docs: {
    prog_cond: string;
    data_proc: string;
    news_offers: string;
  };
}

export interface BonusInfo {
  withdraw: {
    header: string;
    card: string;
    sberprime: string;
    limit: string;
  };
  earn: {
    header: string;
    exchangeBonus: string;
    exchangeRub: string;
    text: string;
  };
  earnInfo: {
    minOrderSum: number;
    percent: number;
  }[];
  consentDocs: {
    progCond: string;
    dataProc: string;
    newsOffers: string;
  };
}
