import React, { VFC } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  ButtonSizes
} from '@npm-registry/eapteka-ui';
import { getCurrentRegionUrl } from '../../../helpers/getCurrentRegionUrl';
import { getEmail } from '../../../store/forms';
import { FormWrapper } from '../../elements/FormWrapper';
import { FormGroup } from '../../elements/FormGroup';
import { PolicyNotice } from '../../elements/PolicyNotice';
import Styles from './ResetPasswordSuccessForm.scss';

export interface ResetPasswordSuccessFormProps {
  onClose?: () => void;
}

export const ResetPasswordSuccessForm: VFC<
  ResetPasswordSuccessFormProps
> = ({ onClose }) => {
  const email = useSelector(getEmail);

  const onCloseHandler = () => {
    location.pathname = getCurrentRegionUrl();
  };

  const onCloseFunction = onClose || onCloseHandler;

  return (
    <FormWrapper
      title="Восстановить пароль"
      onClose={onClose}
    >
      <div className={Styles.resetPasswordSuccessForm}>
        <FormGroup>
          <div
            className={Styles.resetPasswordSuccessFormInfo}
          >
            Мы отправили ссылку для смены пароля на почту{' '}
            <b>{email}</b>
          </div>
        </FormGroup>
        <FormGroup>
          <Button
            className={
              Styles.resetPasswordSuccessFormButton
            }
            size={ButtonSizes.s}
            onClick={onCloseFunction}
          >
            Ок
          </Button>
        </FormGroup>
        <PolicyNotice />
      </div>
    </FormWrapper>
  );
};
