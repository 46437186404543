import { SberFlowInitiator } from '../../types/flows';

export const sberIdSuccessMessagesMap = {
  [SberFlowInitiator.ssoApp]: 'ssoAppSecondPage_success',
  [SberFlowInitiator.ssoWeb]: 'ssoWebSecondPage_success',
  [SberFlowInitiator.authBtn]: 'sberIdAuthApprove_success'
};

export const sberIdFailedMessages = 'sberIdAuthApprov_fail';

export const sendSberIdFinalizeAnalyticEvent = (
  success: boolean
) => {
  try {
    const sberIdTypeString = localStorage.getItem('sberid');

    let type: SberFlowInitiator;

    if (sberIdTypeString in SberFlowInitiator) {
      type = sberIdTypeString as SberFlowInitiator;
    }

    const timeFirstAuth = JSON.parse(
      localStorage.getItem('timeFirstAuth')
    );
    const timeSecondAuth = new Date().getTime() / 1000;
    const timeSpend = timeSecondAuth - timeFirstAuth;

    if (window.ga) {
      window.ga(
        'send',
        'event',
        'Auth',
        success
          ? sberIdSuccessMessagesMap[type] || ''
          : sberIdFailedMessages,
        timeSpend
      );
    }
  } catch (error) {
    console.error(
      'SberId auth success event send failed: ',
      error
    );
  }

  localStorage.removeItem('sberid');
  localStorage.removeItem('timeFirstAuth');
};
