import React, { useState } from 'react';
import Styles from './CheckBoxPolicy.scss';
import { Checkbox, Link } from '@npm-registry/eapteka-ui';

const policyLink = '/company/policy/';
const companyContract = '/company/contract/';
const loyaltyRules = '/loyalty/rules/';
const acceptSpam = '/landing/soglasie_na_rassylku/';

const LinkCompanyContract = () => {
  return (
    <Link href={companyContract} target="_blank">
      пользовательским соглашением
    </Link>
  );
};
const LinkPolicy = () => {
  return (
    <Link href={policyLink} target="_blank">
      политикой конфиденциальности
    </Link>
  );
};
const LinkLoyaltyRules = () => {
  return (
    <Link href={loyaltyRules} target="_blank">
      программой лояльности
    </Link>
  );
};
const LinkAcceptSpam = () => {
  return (
    <Link href={acceptSpam} target="_blank">
      согласие
    </Link>
  );
};

const CheckBoxPolicy = () => {
  const [policyCheckbox, setPolicyCheckbox] =
    useState(false);
  const [subscriptionsCheckbox, setSubscriptionsCheckbox] =
    useState(true);

  const toggleCheckMarkPolicy = () => {
    setPolicyCheckbox((prev) => !prev);
  };
  const toggleCheckMarkSubscription = () => {
    setSubscriptionsCheckbox((prev) => !prev);
  };

  return (
    <>
      <div className={Styles.checkboxItem}>
        <Checkbox
          onChange={toggleCheckMarkPolicy}
          checked={policyCheckbox}
        />
        Даю согласие на обработку персональных данных в
        соответствии с <LinkPolicy />, соглашаюсь с{' '}
        <LinkCompanyContract /> и <LinkLoyaltyRules />
      </div>
      <div className={Styles.checkboxItem}>
        <Checkbox
          onChange={toggleCheckMarkSubscription}
          checked={subscriptionsCheckbox}
        />
        Предоставляю <LinkAcceptSpam /> на получение
        рекламных новостей, акций и персональных предложений
      </div>
    </>
  );
};

export default CheckBoxPolicy;
