import { createAsyncThunk } from '@reduxjs/toolkit';
import { passwordResetRequest } from '../../../api/auth';
import { ScreenCode } from '../../../types/screens';
import { errorsMap } from '../../../consts/errors';
import { ErrorCode } from '../../../types/errors';
import { screenActions } from '../../screen';
import { TRootState } from '../../rootReducer';
import { passwordResetFormValidator } from '../validators/passwordResetForm';
import { formsActions } from '../slice';
import { getEmail } from '../selectors';
import { formsStoreName } from '../consts';

export const submitResetPassword = createAsyncThunk(
  formsStoreName + '/resetPasswordSubmit',
  async (_, thunkAPI) => {
    const store = thunkAPI.getState() as TRootState;
    const email = getEmail(store);

    const errorsList = await passwordResetFormValidator({
      email
    });

    if (!errorsList.length) {
      thunkAPI.dispatch(formsActions.setRequestState(true));

      try {
        const result = await passwordResetRequest({
          email
        });

        if (result.status === 200) {
          thunkAPI.dispatch(
            screenActions.changeScreen(
              ScreenCode.passwordRecoverySuccess
            )
          );
        } else {
          thunkAPI.dispatch(
            formsActions.updateErrorsList([
              errorsMap[
                ErrorCode.invalidEmailResetRequestError
              ]
            ])
          );
        }
      } catch (error) {
        thunkAPI.dispatch(
          formsActions.updateErrorsList([
            errorsMap[
              ErrorCode.invalidEmailResetRequestError
            ]
          ])
        );
      }

      thunkAPI.dispatch(
        formsActions.setRequestState(false)
      );
    } else {
      thunkAPI.dispatch(
        formsActions.updateErrorsList(errorsList)
      );
    }
  }
);
