import React from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from '../../../store/screen';
import { ModalWrapper } from '../../elements/ModalWrapper';
import { SetPasswordForm } from '../SetPasswordForm';

export const SetPasswordModal = ({
  onClose
}: {
  onClose?: () => void;
}) => {
  const dispatch = useDispatch();

  const closeHandler = () => {
    if (onClose) {
      onClose();
      return;
    }
    dispatch(closeModal());
  };

  return (
    <ModalWrapper onClose={closeHandler}>
      <SetPasswordForm onClose={closeHandler} />
    </ModalWrapper>
  );
};
