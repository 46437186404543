import React, { FC } from 'react';
import { Modal } from '@npm-registry/eapteka-ui';
import { ModalWrapperProps } from './types';
import Styles from './ModalWrapper.scss';

export const ModalWrapper: FC<ModalWrapperProps> = ({
  onClose,
  children
}) => (
  <Modal
    className={Styles.modalWrapperModal}
    classNameContainer={Styles.modalWrapper}
    onClose={onClose}
    visible
  >
    {children}
  </Modal>
);
