import {
  createSlice,
  type PayloadAction
} from '@reduxjs/toolkit';
import { getUserAuthState } from '../../helpers/backwardCompatibility/getUserAuthState';
import type { User } from '../../types/user';
import { userStoreName } from './consts';
import type { UserStore } from './types';

const getInitialState = (): UserStore => ({
  user: null,
  isUserAuth: getUserAuthState()
});

const initialState = getInitialState();

export const userSlice = createSlice({
  name: userStoreName,
  initialState,
  reducers: {
    setUser: (
      state,
      { payload }: PayloadAction<User | null>
    ) => {
      state.user = payload;
    },
    setUserAuthState: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isUserAuth = payload;
    },
    logoutUser: (state) => {
      state.user = null;
      state.isUserAuth = false;
    }
  }
});

const userReducers = userSlice.reducer;

const userActions = userSlice.actions;

export { userReducers, userActions, initialState };
