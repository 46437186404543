import { getDefaultCity } from './backwardCompatibility/getDefaultCity';

const pageMap = {
  '/': 'Home',
  '/personal/cart/': 'Cart',
  '/personal/profile/': 'Profile',
  '/personal/bonus_program/': 'Bonus_Program',
  '/personal/offers/': 'Personal_Promocode',
  '/goods/': 'Offer',
  '/personal/order/make/': 'Cart_Order_Make',
  '/auth/': 'Apteka_Auth'
};

type PageMapKeys = keyof typeof pageMap;

export const getPageName =
  (): (typeof pageMap)[PageMapKeys] => {
    const defaultCity = getDefaultCity();
    const url = defaultCity.url || '/';
    const pathname = window.location.pathname.replace(
      url.replace('/', ''),
      ''
    );

    if (pathname.includes('/id')) {
      const idPart = pathname
        .split('/')
        .filter((e) => e.includes('id'));
      const idPath = pathname.replace(idPart + '/', '');

      return (
        pageMap?.[idPath as PageMapKeys] || 'Other_Page'
      );
    }

    return (
      pageMap?.[pathname as PageMapKeys] || 'Other_Page'
    );
  };
