import React, {
  type FC,
  type ChangeEvent,
  type ReactNode
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from '@npm-registry/eapteka-ui';
import { SUBSCRIPTION_LANDING } from '../../../config';
import {
  formsActions,
  getPolicyState,
  getSubscriptionState
} from '../../../store/forms';
import { addCityToPath } from '../../../helpers/addCityToPath';
import { FormGroup } from '../FormGroup';
import { CheckboxGroup } from '../CheckboxGroup';
import { policyBlockDefaultSuggestionBlock } from './PolicyBlockDefaultSuggestionBlock';

export interface PolicyBlockProps {
  suggestionBlock?: ReactNode;
}

export const PolicyBlock: FC<PolicyBlockProps> = ({
  children = policyBlockDefaultSuggestionBlock
}) => {
  const dispatch = useDispatch();

  const isPolicyConfirm = useSelector(getPolicyState);
  const isSubscriptionConfirm = useSelector(
    getSubscriptionState
  );

  const policyStateChangeHandler = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(
      formsActions.setPolicyState(event.target.checked)
    );
  };

  const subscriptionStateChangeHandler = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(
      formsActions.setSubscriptionState(
        event.target.checked
      )
    );
  };

  return (
    <>
      <FormGroup>
        <CheckboxGroup
          checked={isPolicyConfirm}
          onChange={policyStateChangeHandler}
          error={!isPolicyConfirm}
        >
          {children}
        </CheckboxGroup>
      </FormGroup>
      <FormGroup>
        <CheckboxGroup
          checked={isSubscriptionConfirm}
          onChange={subscriptionStateChangeHandler}
        >
          Предоставляю{' '}
          <Link
            href={addCityToPath(SUBSCRIPTION_LANDING)}
            target="_blank"
          >
            согласие на получение рекламных новостей, акций
            и персональных предложений
          </Link>
        </CheckboxGroup>
      </FormGroup>
    </>
  );
};
