import { ScreenCode, ScreenView } from '../types/screens';

import { AuthorizationPhoneModal } from '../components/blocks/AuthorizationPhoneModal';
import { AuthorizationPhoneForm } from '../components/blocks/AuthorizationPhoneForm';

import { AuthorizationEmailModal } from '../components/blocks/AuthorizationEmailModal';
import { AuthorizationEmailForm } from '../components/blocks/AuthorizationEmailForm';

import { AuthorizationCodeModal } from '../components/blocks/AuthorizationCodeModal';
import { AuthorizationCodeForm } from '../components/blocks/AuthorizationCodeForm';

import { AuthorizationSberIdModal } from '../components/blocks/AuthorizationSberIdModal';
import { AuthorizationSberIdForm } from '../components/blocks/AuthorizationSberIdForm';

import { ResetPasswordModal } from '../components/blocks/ResetPasswordModal';
import { ResetPasswordForm } from '../components/blocks/ResetPasswordForm';

import { ResetPasswordSuccessModal } from '../components/blocks/ResetPasswordSuccessModal';
import { ResetPasswordSuccessForm } from '../components/blocks/ResetPasswordSuccessForm';

import { SetPasswordModal } from '../components/blocks/SetPasswordModal';
import { SetPasswordForm } from '../components/blocks/SetPasswordForm';

export const screensFormComponentsMap = {
  [ScreenCode.phoneEnter]: AuthorizationPhoneForm,
  [ScreenCode.emailEnter]: AuthorizationEmailForm,
  [ScreenCode.codeEnter]: AuthorizationCodeForm,
  [ScreenCode.sberIdPhoneEnter]: AuthorizationSberIdForm,
  [ScreenCode.passwordRecovery]: ResetPasswordForm,
  [ScreenCode.passwordRecoverySuccess]:
    ResetPasswordSuccessForm,
  [ScreenCode.setPassword]: SetPasswordForm
};

export const screensModalComponentsMap = {
  [ScreenCode.phoneEnter]: AuthorizationPhoneModal,
  [ScreenCode.emailEnter]: AuthorizationEmailModal,
  [ScreenCode.codeEnter]: AuthorizationCodeModal,
  [ScreenCode.sberIdPhoneEnter]: AuthorizationSberIdModal,
  [ScreenCode.passwordRecovery]: ResetPasswordModal,
  [ScreenCode.passwordRecoverySuccess]:
    ResetPasswordSuccessModal,
  [ScreenCode.setPassword]: SetPasswordModal
};

export const screensViewsMap = {
  [ScreenView.Form]: screensFormComponentsMap,
  [ScreenView.Modal]: screensModalComponentsMap
};
