// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button.w4FGeh7{width:100%;font-family:var(--control-font-family);font-size:16px;font-weight:normal;line-height:24px}button.vXa0rJz{--button-border-width: 1px;border-width:1px}.KuYfh5r{animation:cg6Kwo4 1s linear}@keyframes cg6Kwo4{0%{transform:rotate(0)}100%{transform:rotate(360deg)}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formButton": `w4FGeh7`,
	"formButtonReset": `vXa0rJz`,
	"formButtonLoader": `KuYfh5r`,
	"loaderRotate": `cg6Kwo4`
};
export default ___CSS_LOADER_EXPORT___;
