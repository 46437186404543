// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.d5PIvie{position:relative;display:inline-block;overflow:hidden;width:100%;margin-bottom:0;caret-color:#9199a3;color:#9199a3}.J8P6cQx{position:relative;display:inline-block;width:100%;height:auto;padding:23px 16px 4px;font-size:14px;font-weight:normal;line-height:20px;color:#333f48;border:1px solid #e2e2e2;border-radius:10px;outline:0;background-color:#fff;box-shadow:none;transition:border ease .3s,background-color ease .3s}.U1GDldJ{caret-color:#ff1f78;border-color:#ff1f78;box-shadow:0 1px 8px rgba(253,53,100,.2),0 0 0 30px #fff inset}.J8P6cQx:focus{caret-color:#8168f0;border-color:#8168f0;box-shadow:0 1px 8px rgba(129,104,240,.2),0 0 0 30px #fff inset}.cDbaKX9{position:absolute;top:50%;left:16px;display:flex;overflow:hidden;justify-content:center;align-items:center;white-space:nowrap;text-overflow:ellipsis;pointer-events:none;transition:top ease .3s,font-size ease .3s,transform ease .3s,color ease .3s;transform:translateY(-50%)}.J8P6cQx:focus+.cDbaKX9,.V5153NR+.cDbaKX9{top:3px;font-size:12px;transform:translateY(0%)}.U1GDldJ+.cDbaKX9{color:#ff1f78}.J8P6cQx:focus+.cDbaKX9{color:#8168f0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"labeledInput": `d5PIvie`,
	"labeledInputField": `J8P6cQx`,
	"labeledInputFieldError": `U1GDldJ`,
	"labeledInputLabel": `cDbaKX9`,
	"labeledInputFieldFilled": `V5153NR`
};
export default ___CSS_LOADER_EXPORT___;
