import React, { type FC } from 'react';
import { Provider } from 'react-redux';
import { StoreRegistry } from '../../store';
import { AuthorizationFormEntryPointHoc } from './AuthorizationFormEntryPointHoc';
import { AuthorizationFormEntryPointHocProps } from './types';

export const AuthorizationFormEntryPoint: FC<
  AuthorizationFormEntryPointHocProps
> = (props) => (
  <Provider store={StoreRegistry.instance.store}>
    <AuthorizationFormEntryPointHoc {...props} />
  </Provider>
);
