import { generateUniqueId } from '../../../helpers/generateUniqueId';
import { personalizedButtonElementType } from '../../../types/personalizedButton.types';

let buttonElement: personalizedButtonElementType = null;
const listeners: {
  [key: string]: (
    element: personalizedButtonElementType
  ) => void;
} = {};

export const setPersonalizedButtonElement = (
  element: personalizedButtonElementType
) => {
  buttonElement = element;
  Object.values(listeners).forEach((listener) =>
    listener(element)
  );
};

export const getPersonalizedButtonElement =
  (): personalizedButtonElementType => buttonElement;

export const subscribeToPersonalizedButtonElement = (
  listener: (element: personalizedButtonElementType) => void
): (() => void) => {
  const uid = generateUniqueId();
  listeners[uid] = listener;

  return () => {
    delete listeners[uid];
  };
};
