import { ErrorsList, FieldError } from '../../types/errors';

export interface ValidatorsGeneratorPropItem {
  condition: () => Promise<boolean>;
  error: FieldError;
}

export const validatorsGenerator = async (
  validators: ValidatorsGeneratorPropItem[]
): Promise<ErrorsList> => {
  const validationResult = await Promise.all(
    validators.map(({ condition }) => condition())
  );

  return validationResult.reduce(
    (errorsList, result, resultIndex) => {
      if (result && validators[resultIndex]?.error) {
        errorsList.push(validators[resultIndex].error);
      }

      return errorsList;
    },
    []
  );
};
