import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  VFC
} from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import {
  Link,
  PinInput,
  Skeleton
} from '@npm-registry/eapteka-ui';
import { ScreenCode } from '../../../types/screens';
import { FormFields } from '../../../types/form';
import { screenActions } from '../../../store/screen';
import {
  clearErrors,
  getErrorMessageByType,
  getPhone,
  getRequestState,
  getSmsLastRequestTimestamp,
  submitCodeForm,
  submitPhoneForm
} from '../../../store/forms';
import { SMS_REQUEST_AWAIT_TIME } from '../../../config';
import { FormWrapper } from '../../elements/FormWrapper';
import { AuthorizationCodeFormProps } from './types';
import Styles from './AuthorizationCodeForm.scss';

export const AuthorizationCodeForm: VFC<
  AuthorizationCodeFormProps
> = ({ onClose }) => {
  const dispatch = useDispatch();
  const lastSMSTimestamp = useSelector(
    getSmsLastRequestTimestamp
  );
  const isRequestInProgress = useSelector(getRequestState);
  const error = useSelector(
    getErrorMessageByType(FormFields.smsCode)
  );
  const phone = useSelector(getPhone);
  const smsIntervalRef = useRef<NodeJS.Timeout>(null);
  const [timeLeft, setTimeLeft] = useState(null);

  const phoneResetClickHandler = () => {
    dispatch(
      screenActions.changeScreen(ScreenCode.phoneEnter)
    );
  };

  const emailLoginClickHandler = () => {
    dispatch(
      screenActions.changeScreen(ScreenCode.emailEnter)
    );
  };

  const resendSMSClickHandler = () => {
    dispatch(submitPhoneForm());
  };

  const submitForm = (value: string) => {
    dispatch(submitCodeForm({ code: value }));
  };

  const clearErrorsHandler = () => {
    dispatch(clearErrors());
  };

  useEffect(() => {
    const clearCurrentInterval = () => {
      if (smsIntervalRef.current) {
        clearInterval(smsIntervalRef.current);
      }
    };

    const updateLeftTime = () => {
      const timeLeft =
        SMS_REQUEST_AWAIT_TIME +
        lastSMSTimestamp -
        Date.now();

      if (timeLeft <= 0) {
        clearCurrentInterval();
      }
      setTimeLeft(timeLeft);
    };

    clearCurrentInterval();
    updateLeftTime();

    smsIntervalRef.current = setInterval(
      updateLeftTime,
      1000
    );

    return clearCurrentInterval;
  }, [lastSMSTimestamp]);

  const formattedTime = useMemo(() => {
    const timeLeftDate = new Date(timeLeft);

    return timeLeftDate.toLocaleString('ru', {
      minute: 'numeric',
      second: 'numeric'
    });
  }, [timeLeft]);

  return (
    <FormWrapper
      title="Введите код из SMS"
      onClose={onClose}
    >
      <div className={Styles.authorizationCodeForm}>
        <div
          className={Styles.authorizationCodeFormFieldGroup}
        >
          {isRequestInProgress && (
            <Skeleton
              className={
                Styles.authorizationCodeFormFieldSkeleton
              }
            />
          )}
          {!isRequestInProgress && (
            <>
              <PinInput
                className={clsx(
                  Styles.authorizationCodeFormField,
                  {
                    [Styles.authorizationCodeFormFieldError]:
                      error
                  }
                )}
                state={error ? 'error' : undefined}
                onFocus={clearErrorsHandler}
                onComplete={submitForm}
              />
              {error && (
                <div
                  className={
                    Styles.authorizationCodeFormError
                  }
                >
                  {error}
                </div>
              )}
            </>
          )}
        </div>
        <div className={Styles.authorizationCodeFormInfo}>
          <span
            className={Styles.authorizationCodeFormPhone}
          >
            Код выслан на <b>{phone}</b>
          </span>
          <Link
            className={Styles.authorizationCodeFormLink}
            tag="button"
            aria-label="Изменить номер телефона"
            onClick={phoneResetClickHandler}
          >
            Изменить
          </Link>
        </div>
        {timeLeft !== null && timeLeft > 0 && (
          <div className={Styles.authorizationCodeFormInfo}>
            Получить новый код можно через {formattedTime}
          </div>
        )}
        {timeLeft !== null && timeLeft <= 0 && (
          <Link
            className={Styles.authorizationCodeFormLink}
            tag="button"
            onClick={resendSMSClickHandler}
          >
            Выслать под повторно
          </Link>
        )}
        <div className={Styles.authorizationCodeEmailLogin}>
          <Link
            className={Styles.authorizationCodeFormLink}
            tag="button"
            onClick={emailLoginClickHandler}
          >
            Войти по почте
          </Link>
        </div>
      </div>
    </FormWrapper>
  );
};
