import { validatePhone } from '../../../helpers/validators';
import { combineValidators } from '../../../helpers/validators/combineValidators';

export interface PhoneAuthFormValidatorProps {
  phone: string;
}

export const phoneAuthFormValidator = async (
  props: PhoneAuthFormValidatorProps
) => await combineValidators([validatePhone(props.phone)]);
