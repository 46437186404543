import Cookies from 'js-cookie';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { saveRecipe } from '../../../helpers/backwardCompatibility/saveRecipe';
import { createSbUniversalLink } from '../../../helpers/sberId/createSbUniversalLink';
import { sendSberIdStartAnalyticEvent } from '../../../helpers/sberId/sendSberIdStartAnalyticEvent';
import {
  isAndroid,
  isChrome
} from '../../../helpers/browserCheck';
import {
  DeepLinkTypes,
  SourcesTypes
} from '../../../types/sberId';
import { deepLinkFlowMap } from '../../../consts/sberIdFlowsMap';
import { screenActions } from '../../screen/slice';
import { userStoreName } from '../consts';

export const passThroughAuth = createAsyncThunk(
  userStoreName + '/passThroughAuth',
  async (_, thunkAPI) => {
    const searchParams = new URLSearchParams(
      location.search
    );
    const deepLinkValue = searchParams.get(
      'deep_link_value'
    );
    const sourceValue = searchParams.get('c');

    if (
      deepLinkValue &&
      deepLinkValue in DeepLinkTypes &&
      sourceValue &&
      typeof window.SberidSDK === 'function'
    ) {
      localStorage.setItem(
        'toUrl',
        location.origin + location.pathname
      );

      if (sourceValue == SourcesTypes.sbolEr) {
        saveRecipe(location.search);
      }

      const link = createSbUniversalLink(
        deepLinkValue as DeepLinkTypes,
        searchParams
      );

      sendSberIdStartAnalyticEvent(
        deepLinkFlowMap[deepLinkValue as DeepLinkTypes]
      );

      //TODO: убрать после ухода от монолита
      document.body.classList.remove(
        'is-loading',
        'is-loading--page'
      );

      if (link) {
        Cookies.set('linkSBId', link);

        if (
          deepLinkValue === DeepLinkTypes.authBySberIDApp &&
          isAndroid &&
          isChrome
        ) {
          location.assign(
            '/login/oauth/sberid/redirect.php'
          );
        } else {
          location.assign(link);
        }

        thunkAPI.dispatch(
          screenActions.setRebaseState(true)
        );
      }
    }
  }
);
