import React from 'react';
import { Link } from '@npm-registry/eapteka-ui';
import {
  COMPANY_CONTRACT,
  COMPANY_POLICY
} from '../../../config';
import { addCityToPath } from '../../../helpers/addCityToPath';

export const policyBlockDefaultSuggestionBlock = (() => (
  <>
    Соглашаюсь с{' '}
    <Link
      href={addCityToPath(COMPANY_POLICY)}
      target="_blank"
    >
      политикой конфиденциальности
    </Link>
    ,{' '}
    <Link
      href={addCityToPath(COMPANY_CONTRACT)}
      target="_blank"
    >
      пользовательским соглашением
    </Link>
  </>
))();
