import {
  createSlice,
  type PayloadAction
} from '@reduxjs/toolkit';
import type { ErrorsList } from '../../types/errors';
import { type FormsStore, SetFieldsProps } from './types';
import { formsStoreName } from './consts';

export const getInitialState = (): FormsStore => ({
  email: '',
  phone: '',
  password: '',
  passwordConfirm: '',
  checkWord: '',
  smsLastRequestTimestamp: null,
  isPolicyConfirm: false,
  isSubscriptionConfirm: true,
  isRequestInProgress: false,
  errorsList: []
});

const initialState = getInitialState();

const formsSlice = createSlice({
  name: formsStoreName,
  initialState,
  reducers: {
    setEmail: (
      state,
      { payload }: PayloadAction<string>
    ) => {
      state.email = payload;
    },
    setCheckWord: (
      state,
      { payload }: PayloadAction<string>
    ) => {
      state.checkWord = payload;
    },
    setPhone: (
      state,
      { payload }: PayloadAction<string>
    ) => {
      state.phone = payload;
    },
    setPassword: (
      state,
      { payload }: PayloadAction<string>
    ) => {
      state.password = payload;
    },
    setField: (
      state,
      { payload }: PayloadAction<SetFieldsProps>
    ) => {
      state[payload.field] = payload.value;
    },
    setPolicyState: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isPolicyConfirm = payload;
    },
    setSubscriptionState: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isSubscriptionConfirm = payload;
    },
    updateErrorsList: (
      state,
      { payload }: PayloadAction<ErrorsList>
    ) => {
      state.errorsList = payload;
    },
    setRequestState: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isRequestInProgress = payload;
    },
    setSmsLastRequestTimestamp: (
      state,
      { payload }: PayloadAction<number | null>
    ) => {
      state.smsLastRequestTimestamp = payload;
    }
  }
});

const formsReducers = formsSlice.reducer;
const formsActions = formsSlice.actions;

export { formsReducers, formsActions, initialState };
