// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lNOCh22{max-width:540px;font-family:var(--control-font-family)}.K9YReZa{padding:0 35px}@media(max-width: 480px){.K9YReZa{padding:0 24px}}.XbkPveB{position:relative;display:flex;align-items:flex-end;height:105px;margin-bottom:16px}.wrWxL2r{position:absolute;top:0;right:0;color:#99999b}h2.d2YNtBw{font-weight:600}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formWrapper": `lNOCh22`,
	"formWrapperContainer": `K9YReZa`,
	"formWrapperHead": `XbkPveB`,
	"formWrapperClose": `wrWxL2r`,
	"formWrapperTitle": `d2YNtBw`
};
export default ___CSS_LOADER_EXPORT___;
