import type { DateString, UrlString } from './common';
import type { CartToken, UserToken } from './tokens';
export type UserId = number;

export enum BonusProgramLevelsCode {
  BASE = 'BASE',
  START = 'START',
  COPPER = 'COPPER',
  SILVER = 'SILVER',
  GOLD = 'GOLD',
  PLATINUM = 'PLATINUM'
}

export interface BonusLevelResponse {
  name: string;
  percent: number;
  percent_get: number;
  can_have_double_bonus: boolean;
  next_level_sum: number;
  next_level_left: number;
  next_level_title: string;
  subtext: string;
  text: string;
}

export interface BonusLevel {
  name: string;
  percent: number;
  percentGet: number;
  canHaveDoubleBonus: boolean;
  nextLevelSum: number;
  nextLevelLeft: number;
  nextLevelTitle: string;
  subtext: string;
  text: string;
}

export type BonusProgramResponseLevelsMap = {
  [key in BonusProgramLevelsCode]: BonusLevelResponse;
};

export type BonusProgramLevelsMap = {
  [key in BonusProgramLevelsCode]: BonusLevel;
};

export interface BonusProgramResponse {
  active: boolean;
  email_confirmed: boolean;
  phone_verified: boolean;
  email_verified: boolean;
  balance: number;
  level: BonusProgramLevelsCode;
  referral_code: string;
  bonus_threshold: number;
  total_collected: number;
  double_points_orders_left: number;
  about: string;
  about_expire: string;
  about_bonus: string;
  levels: BonusProgramResponseLevelsMap;
}

export interface BonusProgram {
  active: boolean;
  emailConfirmed: boolean;
  emailVerified: boolean;
  phoneVerified: boolean;
  balance: number;
  level: BonusProgramLevelsCode;
  referralCode: string;
  bonusThreshold: number;
  totalCollected: number;
  doublePointsOrdersLeft: number;
  about: string;
  aboutExpire: string;
  aboutBonus: string;
  levels: BonusProgramLevelsMap;
}

export interface SberprimeResponse {
  expire: DateString;
  subscription_type: string; // тут по идее Enum
  image: UrlString;
  image_short: UrlString;
  privilege: string; // тут по идее Enum
  cashback: string;
  manage_link: UrlString;
  delivery: {
    free_disabled: boolean;
    free_from: number;
    price: number;
  };
}

export interface Sberprime {
  expire: DateString;
  subscriptionType: string; // тут по идее Enum
  image: UrlString;
  imageShort: UrlString;
  privilege: string; // тут по идее Enum
  cashback: string;
  manageLink: UrlString;
  delivery: {
    freeDisabled: boolean;
    freeFrom: number;
    price: number;
  };
}

export interface AuthResponse {
  id: UserId;
  name: string;
  phone: string;
  email: string;
  user_token: UserToken;
  cart_token: CartToken;
  register: DateString;
  birthday: DateString;
  refferal_code: string;
  callback_route: string;
  favorite_store: string;
  user_delete_timeout: number;
  sber_id_enabled: boolean;
  accepted_check_erecipe: boolean;
  promocode?: {
    count_active: number;
  };
  bonus_program?: BonusProgramResponse;
  sberprime?: SberprimeResponse;
}

export interface User {
  id: UserId;
  name: string;
  phone: string;
  email: string;
  userToken: UserToken;
  cartToken: CartToken;
  register: DateString;
  birthday: DateString;
  refferalCode: string;
  callbackRoute: string;
  favoriteStore: string;
  userDeleteTimeout: number;
  sberIdEnabled: boolean;
  acceptedCheckErecipe: boolean;
  promocode?: {
    countActive: number;
  };
  bonusProgram?: BonusProgram;
  sberprime?: Sberprime;
}
