// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.XWpJy96 {
  max-width: 418px;

  font-family: var(--control-font-family);
}

/* Перебиваем стили библиотеки компонентов */
.DFgkQaL .XWpJy96 {
  margin: 0;
  padding: 0 0 24px;
}

.C2b4LSi {
  padding: 0 45px;
}

.mCZ96wD {
  position: relative;

  display: flex;
  align-items: flex-end;

  height: 105px;
  margin-bottom: 16px;
}

.WBFij1c {
  position: absolute;
  top: 0;
  right: 0;

  color: #99999b;
}

.PL3dgV9 {
  font-weight: 600;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalWrapper": `XWpJy96`,
	"modalWrapperModal": `DFgkQaL`,
	"modalWrapperContainer": `C2b4LSi`,
	"modalWrapperHead": `mCZ96wD`,
	"modalWrapperClose": `WBFij1c`,
	"modalWrapperTitle": `PL3dgV9`
};
export default ___CSS_LOADER_EXPORT___;
