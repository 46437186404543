import { createAsyncThunk } from '@reduxjs/toolkit';
import { sendSberIdFinalizeAnalyticEvent } from '../../../helpers/sberId/sendSberIdFinalizeAnalyticEvent';
import { getTokensFromStorage } from '../../../helpers/tokens/getTokensFromStorage';
import { SBER_ID_SDK_CONFIG } from '../../../config';
import { LoginFlow } from '../../../types/flows';
import { userStoreName } from '../consts';
import { getUser } from '../actionCreators';

export const confirmAuthState = createAsyncThunk(
  userStoreName + '/confirmAuthState',
  async (_, thunkAPI) => {
    const tokens = getTokensFromStorage();
    const sberIdTypeString = localStorage.getItem('sberid');
    const authFlow = localStorage.getItem('authBy');

    thunkAPI.dispatch(getUser());

    if (sberIdTypeString) {
      sendSberIdFinalizeAnalyticEvent(true);
    }

    if (
      tokens.userToken &&
      authFlow === LoginFlow.sberid &&
      typeof window.SberidSDK === 'function'
    ) {
      const { BX, SberidSDK } = window;
      const oidcParams = BX.oidcParams;

      new SberidSDK({
        oidc: oidcParams,
        sa: SBER_ID_SDK_CONFIG.sa,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        updateCookiePeriod: 5,
        autoUpdateCookie: true
      });
    }
  }
);
