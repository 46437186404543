import type { UserId } from '../../types/user';
import type { LoginFlow } from '../../types/flows';
import { getPageName } from '../getPageName';
import { sendAmplitudeAnalyticsEvent } from './sendAmplitudeAnalyticsEvent';
import { dataLayerPush } from './dataLayer';
import { AnalyticsEvents } from './types';

export const AnalyticEventsMap = {
  [AnalyticsEvents.userIdSet]: (userID: UserId) => {
    dataLayerPush({
      event: 'setUserId',
      userID
    });
  },
  [AnalyticsEvents.loginSuccessful]: (flow: LoginFlow) => {
    sendAmplitudeAnalyticsEvent({
      id: 'd-v1-e1',
      name: 'Login Successful',
      customParams: {
        login_method: flow,
        referral_link: getPageName()
      }
    });
  },
  [AnalyticsEvents.loginUnsuccessful]: (
    status: number | string,
    flow: LoginFlow
  ) => {
    sendAmplitudeAnalyticsEvent({
      id: 'd-v1-e102',
      name: 'Login Unsuccessful',
      customParams: {
        error_code: status,
        referral_link: getPageName(),
        login_method: flow
      }
    });
  }
};
