import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getIsLoadingPersonalizedButton } from '../../../store/sberId';

export const useAuthorizationSberIdFormLoading = () => {
  const [isLoading, setIsLoading] = useState(true);

  const isLoadingPersonalizedButton = useSelector(
    getIsLoadingPersonalizedButton
  );

  const enableLoading = () => setIsLoading(true);
  const disableLoading = () => setIsLoading(false);

  useEffect(() => {
    let isCancelled = false;

    if (isLoadingPersonalizedButton) return;

    const timeoutId = setTimeout(() => {
      if (!isCancelled) {
        disableLoading();
      }
    }, 500);

    return () => {
      isCancelled = true;
      clearTimeout(timeoutId);
    };
  }, [isLoadingPersonalizedButton]);

  return {
    isLoading,
    enableLoading,
    disableLoading
  };
};
