import React from 'react';
import { IcSberIDBackgroundPath } from '../../../../assets';
import Styles from './AuthorizationSberIdFormHOCBackground.scss';

export const AuthorizationSberIdFormHOCBackground = () => (
  <div className={Styles.background}>
    <img
      src={IcSberIDBackgroundPath}
      alt="sberIDBackground"
    />
  </div>
);
