import React, { type VFC } from 'react';
import { AuthorizationSberIdFormHOCHead } from './AuthorizationSberIdFormHOCHead';
import { AuthorizationSberIdFormHOCBackground } from './AuthorizationSberIdFormHOCBackground';
import { AuthorizationSberIdFormHOCLoader } from './AuthorizationSberIdFormHOCLoader';
import { AuthorizationSberIdFormHOCPolicy } from './AuthorizationSberIdFormHOCPolicy';
import type { AuthorizationSberIdFormHOCProps } from './AuthorizationSberIdFormHOC.types';
import { AuthorizationSberIdFormHOCInfo } from './AuthorizationSberIdFormHOCInfo';
import {
  goodsPathText,
  personalOrderMakePathText
} from './AuthorizationSberIdFormHOC.consts';
import Styles from './AuthorizationSberIdFormHOC.scss';

export const AuthorizationSberIdFormHOC: VFC<
  AuthorizationSberIdFormHOCProps
> = ({
  formElement,
  buttonElement,
  isLoading,
  onClose,
  onSubmitHandler
}) => {
  const currentPath = window.location.pathname;

  const isPersonalOrderMakePath = currentPath.includes(
    '/personal/order/make/'
  );

  const hasGoodsIdPath = /\/goods\/id\d+/.test(currentPath);

  const isInfoAvailable =
    isPersonalOrderMakePath || hasGoodsIdPath;

  return (
    <div className={Styles.wrapper}>
      <AuthorizationSberIdFormHOCBackground />
      {isLoading && <AuthorizationSberIdFormHOCLoader />}
      <div className={Styles.container}>
        <AuthorizationSberIdFormHOCHead onClose={onClose} />
        {isInfoAvailable && (
          <AuthorizationSberIdFormHOCInfo
            text={
              isPersonalOrderMakePath
                ? personalOrderMakePathText
                : goodsPathText
            }
          />
        )}
        {isLoading && (
          <div className={Styles.placeholder} />
        )}
        {!isLoading && (
          <form onSubmit={onSubmitHandler}>
            {formElement}
            <AuthorizationSberIdFormHOCPolicy />
            {buttonElement}
          </form>
        )}
      </div>
    </div>
  );
};
