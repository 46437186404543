import { validateEmail } from '../../../helpers/validators';
import { combineValidators } from '../../../helpers/validators/combineValidators';

export interface PhoneResetFromValidatorProps {
  email: string;
}

export const passwordResetFormValidator = async (
  props: PhoneResetFromValidatorProps
) => await combineValidators([validateEmail(props.email)]);
