import { ErrorCode } from '../../types/errors';
import { errorsMap } from '../../consts/errors';
import { validatorsGenerator } from './validatorsGenerator';

export const validatePassword = async (password: string) =>
  validatorsGenerator([
    {
      condition: async () => password.length < 6,
      error: errorsMap[ErrorCode.invalidPassword]
    }
  ]);

export const validatePasswords = async (
  password: string,
  passwordConfirm: string
) =>
  validatorsGenerator([
    {
      condition: async () => password === passwordConfirm,
      error: errorsMap[ErrorCode.invalidPasswordConfirm]
    }
  ]);
