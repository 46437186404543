import React from 'react';
import { useDispatch } from 'react-redux';
import { screenActions } from '../../../store/screen';
import { ModalWrapper } from '../../elements/ModalWrapper';
import { AuthorizationCodeForm } from '../AuthorizationCodeForm';

export const AuthorizationCodeModal = ({
  onClose
}: {
  onClose?: () => void;
}) => {
  const dispatch = useDispatch();

  const closeHandler = () => {
    if (onClose) onClose();
    dispatch(screenActions.changeScreen(null));
  };

  return (
    <ModalWrapper onClose={closeHandler}>
      <AuthorizationCodeForm onClose={closeHandler} />
    </ModalWrapper>
  );
};
