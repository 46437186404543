import { createSelector } from '@reduxjs/toolkit';
import {
  FormFields,
  type FormFieldsInStore
} from '../../types/form';
import type { ErrorsList } from '../../types/errors';
import type { TRootState } from '../index';
import type { FormsStore } from './types';

export const getFormsState = (
  state: TRootState
): FormsStore => state.forms;

export const getEmail: (state: TRootState) => string =
  createSelector(
    getFormsState,
    (formsStore) => formsStore.email
  );

export const getPhone: (state: TRootState) => string =
  createSelector(
    getFormsState,
    (formsStore) => formsStore.phone
  );

export const getPassword: (state: TRootState) => string =
  createSelector(
    getFormsState,
    (formsStore) => formsStore.password
  );

export const getPasswordConfirm: (
  state: TRootState
) => string = createSelector(
  getFormsState,
  (formsStore) => formsStore.passwordConfirm
);

export const getCheckWord: (state: TRootState) => string =
  createSelector(
    getFormsState,
    (formsStore) => formsStore.checkWord
  );

export const getFormField: (
  field: FormFieldsInStore
) => (state: TRootState) => string = (field) =>
  createSelector(
    getFormsState,
    (formsStore) => formsStore[field]
  );

export const getPolicyState: (
  state: TRootState
) => boolean = createSelector(
  getFormsState,
  (formsStore) => formsStore.isPolicyConfirm
);

export const getSubscriptionState: (
  state: TRootState
) => boolean = createSelector(
  getFormsState,
  (formsStore) => formsStore.isSubscriptionConfirm
);

export const getRequestState: (
  state: TRootState
) => boolean = createSelector(
  getFormsState,
  (formsStore) => formsStore.isRequestInProgress
);

export const getErrorsList: (
  state: TRootState
) => ErrorsList = createSelector(
  getFormsState,
  (formsStore) => formsStore.errorsList
);

export const getSmsLastRequestTimestamp: (
  state: TRootState
) => number = createSelector(
  getFormsState,
  (formsStore) => formsStore.smsLastRequestTimestamp
);

export const getErrorMessageByType: (
  type: FormFields
) => (state: TRootState) => string | null = (
  type: FormFields
) =>
  createSelector(getErrorsList, (errorsList) => {
    const error = errorsList.find((errorItem) =>
      errorItem.fields.includes(type)
    );

    return error?.message || null;
  });

export const isAuthFormSubmitDisabled: (
  state: TRootState
) => boolean = createSelector(
  getEmail,
  getPassword,
  getErrorMessageByType(FormFields.email),
  getErrorMessageByType(FormFields.password),
  getPolicyState,
  (
    email,
    password,
    emailError,
    passwordError,
    isPolicyConfirm
  ) =>
    Boolean(
      !email.length ||
        !password.length ||
        emailError ||
        passwordError ||
        !isPolicyConfirm
    )
);

export const isPhoneAuthFormSubmitDisabled: (
  state: TRootState
) => boolean = createSelector(
  getPhone,
  getErrorMessageByType(FormFields.phone),
  getPolicyState,
  (phone, phoneError, isPolicyConfirm) =>
    Boolean(!phone.length || phoneError || !isPolicyConfirm)
);

export const isPasswordResetFormSubmitDisabled: (
  state: TRootState
) => boolean = createSelector(
  getEmail,
  getErrorMessageByType(FormFields.email),
  getPolicyState,
  (email, emailError, isPolicyConfirm) =>
    Boolean(!email.length || emailError || !isPolicyConfirm)
);
