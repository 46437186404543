import type {
  BonusProgram,
  BonusProgramResponse
} from '../../../types/user';
import { serializeBonusLevels } from './serializeBonusLevels';

export const serializeBonusProgram = (
  bonusProgram: BonusProgramResponse | null
): BonusProgram | null => {
  if (bonusProgram === null) {
    return null;
  }

  return {
    active: bonusProgram.active,
    emailConfirmed: bonusProgram.email_confirmed,
    emailVerified: bonusProgram.email_verified,
    phoneVerified: bonusProgram.phone_verified,
    balance: bonusProgram.balance,
    level: bonusProgram.level,
    referralCode: bonusProgram.referral_code,
    bonusThreshold: bonusProgram.bonus_threshold,
    totalCollected: bonusProgram.total_collected,
    doublePointsOrdersLeft:
      bonusProgram.double_points_orders_left,
    about: bonusProgram.about,
    aboutExpire: bonusProgram.about_expire,
    aboutBonus: bonusProgram.about_bonus,
    levels: serializeBonusLevels(bonusProgram.levels)
  };
};
