// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[6].use[1]!../../elements/ModalWrapper/ModalWrapper.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vlCpW_h{position:relative;padding:0 0 24px;border-radius:24px}div.vqApz_i div.vlCpW_h{margin:0;padding:0}@media(max-width: 768px){div.vqApz_i div.vlCpW_h{display:flex;justify-content:center;min-height:100%;min-width:100%;padding-bottom:10px;border-radius:0}div.vqApz_i div.vlCpW_h>div:first-child{top:15px;min-width:100%}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"authorizationSberIdModal": `vlCpW_h ${___CSS_LOADER_ICSS_IMPORT_0___.locals["modalWrapper"]}`,
	"authorizationSberIdModalWrapper": `vqApz_i`
};
export default ___CSS_LOADER_EXPORT___;
