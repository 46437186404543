import { createAsyncThunk } from '@reduxjs/toolkit';
import { getTokensFromStorage } from '../../../helpers/tokens/getTokensFromStorage';
import type { TRootState } from '../../rootReducer';
// Более длинный импорт, для предотвращения циклической зависимости
import { formsActions } from '../../forms/slice';
import {
  getSberBonusesInfo,
  initHandbook
} from '../../handbook';
import { userStoreName } from '../consts';
import { getUserAuthState } from '../selectors';
import { confirmAuthState } from './confirmAuthState';
import { passThroughAuth } from './passThroughAuth';
import { removeTokensFromStorage } from '../../../helpers/tokens';

export const initAuthState = createAsyncThunk(
  userStoreName + '/initAuthState',
  async (_, thunkAPI) => {
    const isChangePassword = localStorage.getItem(
      'change_password'
    );

    if (isChangePassword) {
      const checkWord = localStorage.getItem(
        'USER_CHECKWORD'
      );
      const email = localStorage.getItem('email');

      thunkAPI.dispatch(
        formsActions.setCheckWord(checkWord)
      );
      thunkAPI.dispatch(formsActions.setEmail(email));
    } else {
      const storeState = thunkAPI.getState() as TRootState;
      const isUserAuth = getUserAuthState(storeState);
      const tokens = getTokensFromStorage();

      thunkAPI.dispatch(passThroughAuth());

      if (isUserAuth) {
        thunkAPI.dispatch(confirmAuthState());
      } else {
        // TODO: Удалить данный путь, после ухода с монолита
        if (tokens.userToken && tokens.cartToken) {
          removeTokensFromStorage();
          document.location.reload();
        }
      }

      await thunkAPI.dispatch(initHandbook());
      thunkAPI.dispatch(getSberBonusesInfo());
    }
  }
);
