import { useEffect, useState } from 'react';

// TODO: добавить получение региона, для хоста
export const useRegion = () => {
  const [region, setRegion] = useState(null);

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      window.__eaRegionWithFeatures
    ) {
      setRegion(window.__eaRegionWithFeatures);
    }
  }, []);

  return region;
};
