import React, { VFC } from 'react';
import { useFromField } from '../../../hooks/useFromField';
import { BaseFieldProps } from '../../../types/form';
import { LabeledInput } from '../LabeledInput';
import { FormGroup } from '../FormGroup';

export const BaseField: VFC<BaseFieldProps> = ({
  label,
  field,
  setter
}) => {
  const { value, onChange, error } = useFromField({
    field,
    setter
  });

  return (
    <FormGroup>
      <LabeledInput
        error={error}
        value={value}
        label={label}
        onChange={onChange}
      />
    </FormGroup>
  );
};
