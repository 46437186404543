import React, { FC, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import clsx from 'clsx';
import { InputTypes } from '../../../types/form';
import { LabeledInputProps } from './types';
import Styles from './LabeledInput.scss';

export const LabeledInput: FC<LabeledInputProps> = ({
  className,
  customType = InputTypes.text,
  value,
  label,
  error,
  onChange,
  ...customProps
}) => {
  const [isFocused, setFocusedState] = useState(false);
  const inputId = useRef(uuid());
  const isFilled = value.trim().length > 0;

  const currentLabel = isFocused ? label : error || label;

  return (
    <label
      className={Styles.labeledInput}
      htmlFor={inputId.current}
    >
      <input
        className={clsx(Styles.labeledInputField, {
          [className]: className,
          [Styles.labeledInputFieldFilled]: isFilled,
          [Styles.labeledInputFieldError]: error
        })}
        id={inputId.current}
        type={customType}
        onFocus={() => setFocusedState(true)}
        onBlur={() => setFocusedState(false)}
        onChange={onChange}
        value={value}
        {...customProps}
      />
      <div className={Styles.labeledInputLabel}>
        {currentLabel}
      </div>
    </label>
  );
};
