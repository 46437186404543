import type { AsyncThunk } from '@reduxjs/toolkit';
import type { SetFieldsProps } from '../store/forms';
import type { AsyncThunkConfig } from './thunk';

export enum InputTypes {
  color = 'color',
  date = 'date',
  'datetime-local' = 'datetime-local',
  email = 'email',
  month = 'month',
  number = 'number',
  password = 'password',
  range = 'range',
  search = 'search',
  tel = 'tel',
  text = 'text',
  time = 'time',
  url = 'url',
  week = 'week'
}

export enum FormFields {
  email = 'email',
  phone = 'phone',
  password = 'password',
  passwordConfirm = 'passwordConfirm',
  smsCode = 'smsCode'
}

export type FormFieldsInStore = Exclude<
  FormFields,
  FormFields.smsCode
>;

export type FieldSetter = AsyncThunk<
  void,
  SetFieldsProps,
  AsyncThunkConfig
>;

export interface BaseFieldProps {
  label: string;
  field: FormFieldsInStore;
  setter: FieldSetter;
}
