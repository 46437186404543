export const buildUrlsWithParams = (
  url: string,
  params: Record<string, string>
) => {
  const newUrl = Object.keys(params)
    .map(function (key) {
      return [key, params[key]]
        .map(encodeURIComponent)
        .join('=');
    })
    .join('&');

  if (url) {
    return ''
      .concat(url)
      .concat(newUrl ? '?' : '')
      .concat(newUrl);
  }

  return newUrl;
};
