import { configureCoreStore, type TStore } from './store';

const StoreRegistrySymbol = Symbol('Store Registry Symbol');
const StoreRegistryEnforcer = Symbol(
  'Store Registry Enforcer'
);

// TODO: Убрать после ухода с монолита
const isWeb = typeof window !== 'undefined';

class StoreRegistry {
  _store: TStore;

  constructor(enforcer: typeof StoreRegistryEnforcer) {
    if (enforcer !== StoreRegistryEnforcer) {
      throw new Error('Cannot construct singleton');
    }

    this._store = configureCoreStore();
  }

  static get instance() {
    if (isWeb) {
      if (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        !window.__appAuthorizationStoreStandaloneRegistry
      ) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.__appAuthorizationStoreStandaloneRegistry =
          new StoreRegistry(StoreRegistryEnforcer);
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return window.__appAuthorizationStoreStandaloneRegistry as StoreRegistry;
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (!this[StoreRegistrySymbol]) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this[StoreRegistrySymbol] = new StoreRegistry(
          StoreRegistryEnforcer
        );
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return this[StoreRegistrySymbol] as StoreRegistry;
    }
  }

  get store(): TStore {
    return this._store;
  }
}

export { StoreRegistry };
