import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react';
import { setLoginTime } from '../../../helpers/localStorage';
import { personalizedButtonElementType } from '../../../types/personalizedButton.types';
import {
  getPersonalizedButtonElement,
  subscribeToPersonalizedButtonElement
} from '../../../shared/services/sberId/personalizedButtonService';
import Styles from '../../../styles/PersonalizedSberIdButton/PersonalizedSberIdButton.scss';
import { getPolicyState } from '../../../store/forms';
import { useSelector } from 'react-redux';
import { setBackUrl } from '../../../helpers/sberId/setBackUrl';

export const useSberIdPersonalizedButtonElement =
  (): personalizedButtonElementType => {
    const [buttonElement, setButtonElement] =
      useState<personalizedButtonElementType>(
        getPersonalizedButtonElement()
      );

    useLayoutEffect(() => {
      const unsubscribe =
        subscribeToPersonalizedButtonElement((element) => {
          setButtonElement(element);
        });

      return () => unsubscribe();
    }, []);

    return buttonElement;
  };

export const usePersonalizedButtonWithPolicyConfirmOnClick =
  (buttonElement: personalizedButtonElementType) => {
    const buttonRef = useRef<HTMLDivElement>(null);

    const isPolicyConfirm = useSelector(getPolicyState);

    useLayoutEffect(() => {
      if (buttonElement && buttonRef.current) {
        buttonRef.current.append(buttonElement);
        buttonElement.classList.remove(Styles.buttonHide);
      }
    }, [buttonElement]);

    const handleClick = useCallback(
      (e) => {
        if (!isPolicyConfirm) return;
        e.preventDefault();
        setBackUrl(window.location.origin + window.location.pathname);
        setLoginTime();
      },
      [isPolicyConfirm]
    );

    useEffect(() => {
      if (!buttonRef.current) return;

      const linkElement =
        buttonRef.current.querySelector('a.sbid-button');
      if (!linkElement) return;

      linkElement.addEventListener('pointerdown', handleClick);

      return () => {
        linkElement.removeEventListener('pointerdown', handleClick);
      };
    }, [handleClick, buttonRef]);

    return { buttonRef, isPolicyConfirm };
  };
