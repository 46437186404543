import React, { VFC, useState } from 'react';
import {
  Button,
  ButtonSizes,
  ButtonThemes,
  Icon
} from '@npm-registry/eapteka-ui';
import {
  type BaseFieldProps,
  InputTypes
} from '../../../types/form';
import { useFromField } from '../../../hooks/useFromField';
import { LabeledInput } from '../LabeledInput';
import { FormGroup } from '../FormGroup';
import Styles from './PasswordField.scss';

export const PasswordField: VFC<BaseFieldProps> = ({
  label,
  field,
  setter
}) => {
  const { value, onChange, error } = useFromField({
    field,
    setter
  });
  const [isPasswordVisible, setPasswordVisibility] =
    useState(false);

  return (
    <FormGroup>
      <LabeledInput
        className={Styles.passwordField}
        customType={
          isPasswordVisible
            ? InputTypes.text
            : InputTypes.password
        }
        error={error}
        value={value}
        label={label}
        onChange={onChange}
      />
      <Button
        className={Styles.passwordFieldToggle}
        theme={ButtonThemes.transparent}
        size={ButtonSizes.s}
        onClick={() =>
          setPasswordVisibility(!isPasswordVisible)
        }
      >
        <Icon
          name={isPasswordVisible ? 'eyeClose' : 'eye'}
        />
      </Button>
    </FormGroup>
  );
};
