import {
  createSlice,
  type PayloadAction
} from '@reduxjs/toolkit';
import { sberIdStoreName } from './consts';
import type { SberIdStore } from './types';

const getInitialState = (): SberIdStore => ({
  isLoadingPersonalizedButton: false,
  isPersonalization: false
});

const initialState = getInitialState();

export const sberIdSlice = createSlice({
  name: sberIdStoreName,
  initialState,
  reducers: {
    setIsLoadingPersonalizedButton: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isLoadingPersonalizedButton = payload;
    },
    setIsPersonalization: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isPersonalization = payload;
    }
  }
});

const sberIdReducers = sberIdSlice.reducer;

const sberIdActions = sberIdSlice.actions;

export { sberIdReducers, sberIdActions, initialState };
