// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a.YYFBSEe{width:100%;font-size:14px;font-weight:normal;line-height:18px;text-align:center;color:#fff;background:#21a038}a.YYFBSEe:hover{background:#1e9032}a.YYFBSEe:disabled{opacity:.4}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sberIdButton": `YYFBSEe`
};
export default ___CSS_LOADER_EXPORT___;
