import {
  Button,
  Icon,
  Modal,
  Title
} from '@npm-registry/eapteka-ui';
import React, { useState } from 'react';
import Styles from './ModalAuth.scss';

type Props = {
  title: string;
  children: string | JSX.Element | JSX.Element[];
};

export const ModalAuth = ({ title, children }: Props) => {
  const [visibleModal, setVisibleModal] = useState(false);

  return (
    <>
      <Button
        variant="filled"
        size="m"
        onClick={() => setVisibleModal(true)}
      >
        Открыть
      </Button>
      <Modal
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
        classNameContainer={Styles.modalContainerBackground}
      >
        <div className={Styles.modalContainerInner}>
          <Title
            weight="semiBold"
            className={Styles.modalTitle}
          >
            {title}
          </Title>
          {children}

          <Button
            theme="transparent"
            className={Styles.modalButtonClose}
            aria-label="Закрыть модальное окно"
            onClick={() => setVisibleModal(false)}
            size="s"
          >
            <Icon
              name="close"
              size="m"
              className={Styles.modalButtonCloseIcon}
            />
          </Button>
        </div>
      </Modal>
    </>
  );
};
