export const COMPANY_POLICY = '/company/policy/';
export const COMPANY_CONTRACT = '/company/contract/';
export const SUBSCRIPTION_LANDING =
  '/landing/soglasie_na_rassylku/';

export const BONUS_PROGRAM_RULES = '/loyalty/rules/';

export const SBER_ID_LINK = 'https://id.sber.ru';

export const SMS_REQUEST_AWAIT_TIME = 60 * 1000;

export const USER_EXTERNAL_REQUEST_TIMEOUT = 10000;

export const COOKIE_UTM_KEY = 'eaptekaSberIdUTMBackup';

export const BASE_API_URL: string =
  typeof API_URL !== 'undefined' ? API_URL : '/api/v3'; // 'https://api.eapteka.ru/api/v3';

export const API_HEADERS: Record<string, string> = {
  'api-key':
    typeof API_KEY !== 'undefined'
      ? API_KEY
      : 'K<8RNCa^ft86LnNa',
  platform: 'frontend'
};

export const SBER_ID_SDK_CONFIG = {
  sa: {
    enable: true,
    // eslint-disable-next-line @typescript-eslint/prefer-as-const
    init: 'auto' as 'auto',
    clientId: '7ed00051-9efa-4fbb-a18f-7bd39a20fcca',
    clientName: 'Eapteka'
  }
};

export const API_URLS_LIST = {
  getUser: '/user',
  userAuth: '/user/auth',
  smsCode: '/user',
  passwordReset: '/user/reset',
  passwordConfirm: '/user/confirm',
  oauthLogin: '/user/oauth',
  handbook: '/handbook/web/features',
  fetchPingSberID: '/sberid/ping',
  getSberBonuses: '/proxy/payment/sber/bonus',
  getSberBonusesInfo: '/handbook/sber/bonus_info'
};
