// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dhKCNR4{position:relative;height:33px}.dhKCNR4::before{position:absolute;top:16px;left:0;width:100%;height:1px;content:"";background:#9199a3}.Ckwdy40{position:relative;display:block;align-items:center;width:86px;margin:0 auto;font-size:14px;line-height:33px;text-align:center;color:#9199a3;background:#fff}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"divider": `dhKCNR4`,
	"dividerLabel": `Ckwdy40`
};
export default ___CSS_LOADER_EXPORT___;
