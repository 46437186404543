import { API_HEADERS, API_URLS_LIST } from '../config';
import type {
  LoginRequestData,
  PasswordResetRequestData,
  SberIDAuthRequestData,
  SMSCodeRequestData,
  PasswordConfirmRequestData
} from '../types/api';
import type { AuthResponse } from '../types/user';
import { apiService } from './apiService';

export const baseMapRequest = <RequestData>(
  params: RequestData
) => ({
  body: JSON.stringify(params)
});

export const baseMapResponse = async <Result>(
  response: Response
): Promise<Result> => {
  if (response.status === 200) {
    try {
      return await response.json();
    } catch (e) {
      throw response;
    }
  }

  throw response;
};

export const loginUserRequest = apiService.createPostMethod<
  LoginRequestData,
  Promise<AuthResponse>
>(API_URLS_LIST.userAuth, {
  mapRequest: baseMapRequest,
  mapResponse: baseMapResponse
});

export const oauthLoginRequest =
  apiService.createPostMethod<
    SberIDAuthRequestData,
    Promise<AuthResponse>
  >(API_URLS_LIST.oauthLogin, {
    mapRequest: (params) => ({
      headers: {
        ...API_HEADERS,
        'Content-type': 'application/json'
      },
      body: JSON.stringify(params)
    }),
    mapResponse: baseMapResponse
  });

export const sendSmsCode = apiService.createPostMethod<
  SMSCodeRequestData,
  Response
>(API_URLS_LIST.smsCode, {
  mapRequest: ({ phone }) => ({
    body: JSON.stringify({
      phone,
      force_sms: true
    })
  }),
  mapResponse: (response) => response
});

export const passwordResetRequest =
  apiService.createPostMethod<
    PasswordResetRequestData,
    Response
  >(API_URLS_LIST.passwordReset, {
    mapRequest: baseMapRequest,
    mapResponse: (response) => response
  });

export const passwordConfirmRequest =
  apiService.createPostMethod<
    PasswordConfirmRequestData,
    Response
  >(API_URLS_LIST.passwordConfirm, {
    mapRequest: baseMapRequest,
    mapResponse: (response) => response
  });
