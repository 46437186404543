import { combineReducers } from '@reduxjs/toolkit';
import { screenReducers } from './screen';
import { formsReducers } from './forms';
import { handbookReducer } from './handbook';
import { userReducers } from './user';
import { sberIdReducers } from './sberId';

export const rootReducer = combineReducers({
  screen: screenReducers,
  forms: formsReducers,
  handbook: handbookReducer,
  user: userReducers,
  sberId: sberIdReducers
});

export type TRootState = ReturnType<typeof rootReducer>;
