import { createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

import {
  getTokensFromStorage,
  removeTokensFromStorage
} from '../../helpers/tokens';
import { getUserRequest } from '../../api/user';
import type { User } from '../../types/user';
import { screenActions } from '../screen/slice';

import { serializeUser } from './serializers/serializeUser';
import { sendUserAuthorizedEvent } from './helpers/sendUserAuthorizedEvent';
import { userStoreName } from './consts';
import { userActions } from './slice';

export const setUserAuthorize = createAsyncThunk(
  userStoreName + '/setUserAuthorize',
  async ({ user }: { user: User }, thunkAPI) => {
    thunkAPI.dispatch(userActions.setUser(user));
    thunkAPI.dispatch(userActions.setUserAuthState(true));

    sendUserAuthorizedEvent(user, true);
  }
);

export const getUser = createAsyncThunk(
  userStoreName + '/getUser',
  async (_, thunkAPI) => {
    const { userToken } = getTokensFromStorage();

    if (userToken) {
      try {
        const response = await getUserRequest();
        const user = serializeUser(response);

        thunkAPI.dispatch(setUserAuthorize({ user }));
      } catch (error) {
        console.error('User req request failed: ', error);
      }
    }
  }
);

export const logoutUser = createAsyncThunk(
  userStoreName + '/logoutUser',
  async (_, thunkAPI) => {
    removeTokensFromStorage();

    if (Cookies.get('linkSBId')) {
      Cookies.remove('linkSBId');
    }

    sendUserAuthorizedEvent(null, false);
    thunkAPI.dispatch(userActions.logoutUser());

    if (!window.isHostApp) {
      document.location.href = '/?logout=yes';

      thunkAPI.dispatch(screenActions.setRebaseState(true));
    }
  }
);
