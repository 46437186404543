import type {
  Handbook,
  HandbookResponse
} from '../../../types/handbook';

export const serializeHandbook = (
  response: HandbookResponse
): Handbook => ({
  availabilityBnpl: response.availability_bnpl,
  eaptekaOnly: response.eapteka_only,
  minBnplSum: response.min_bnpl_sum,
  maxBnplSum: response.max_bnpl_sum,
  isCardTokensEnabled: response.is_card_tokens_enabled,
  isBankIconVisible: response.is_bank_icon_visible,
  isSberSpasiboSwitcherAvailable:
    response.is_sber_spasibo_switcher_available,
  isBindingIdLoyaltyQueryEnabled:
    response.is_binding_id_loyalty_query_enabled,
  isSberBonusesEnabled: response.is_sber_bonuses_enabled,
  isAutoRegisterSberSpasiboToOauth:
    response.is_auto_register_sberspasibo_to_oauth,
  authPrioritizeSberId: response.auth_prioritize_sber_id,
  onlySberId: response.only_sber_id,
  fallbackInsteadSberId: response.fallback_instead_sber_id,
  isPersonalButtonAuthEnabled: response.is_personal_button_auth_enabled,
});
