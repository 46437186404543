import React, { VFC, FormEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormFields } from '../../../types/form';
import {
  getRequestState,
  isPasswordResetFormSubmitDisabled,
  setPasswordConfirmFormField,
  submitPasswordConfirm
} from '../../../store/forms';
import { showStartScreen } from '../../../store/screen/thunks/showStartScreen';
import { FormWrapper } from '../../elements/FormWrapper';
import { PasswordField } from '../../elements/PasswordField';
import { SubmitFormButton } from '../../elements/SubmitFormButton';
import { FormGroup } from '../../elements/FormGroup';
import { Divider } from '../../elements/Divider';
import { FormLink } from '../../elements/FormLink';
import { SetPasswordFormProps } from './types';
import Styles from './SetPasswordForm.scss';

export const SetPasswordForm: VFC<SetPasswordFormProps> = ({
  onClose
}) => {
  const dispatch = useDispatch();
  const isRequestInProgress = useSelector(getRequestState);
  const isSubmitDisable = useSelector(
    isPasswordResetFormSubmitDisabled
  );

  const clickToAuthHandler = () => {
    dispatch(showStartScreen({}));
  };

  const onSubmitHandler = (event: FormEvent) => {
    event.preventDefault();

    dispatch(submitPasswordConfirm());
  };

  return (
    <FormWrapper title="Смена пароль" onClose={onClose}>
      <form
        className={Styles.setPasswordForm}
        onSubmit={onSubmitHandler}
      >
        <PasswordField
          label="Новый пароль"
          field={FormFields.password}
          setter={setPasswordConfirmFormField}
        />
        <PasswordField
          label="Подтверждения пароля"
          field={FormFields.passwordConfirm}
          setter={setPasswordConfirmFormField}
        />
        <FormGroup>
          <SubmitFormButton
            disabled={isSubmitDisable}
            isRequestInProgress={isRequestInProgress}
          >
            Изменить пароль
          </SubmitFormButton>
          <Divider>ИЛИ</Divider>
          <FormLink onClick={clickToAuthHandler}>
            Вернуться к авторизации
          </FormLink>
        </FormGroup>
      </form>
    </FormWrapper>
  );
};
