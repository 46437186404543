// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DdAl5Ew{position:relative;overflow:hidden;max-width:418px;padding:0 26px 24px;line-height:20px;color:#333f48;border-radius:24px}@media(max-width: 768px){.DdAl5Ew{top:-28px;box-shadow:none}}.q0lHHI9{box-shadow:0 0 20px rgba(0,0,0,.2)}.FfYtWWl{--checkbox-group-label-font-size: 14px;position:relative}.mNJdsHv{position:relative;height:241px}@media(min-width: 768px){.mNJdsHv{width:366px}}.gy5kamc{position:relative;z-index:-1}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `DdAl5Ew`,
	"wrapperModal": `q0lHHI9`,
	"container": `FfYtWWl`,
	"placeholder": `mNJdsHv`,
	"formIsLoading": `gy5kamc`
};
export default ___CSS_LOADER_EXPORT___;
