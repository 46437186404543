import Cookies from 'js-cookie';
import { UserEvents } from './events';

export const getUserAuth = () =>
  new Promise((resolve) => {
    let timeout: NodeJS.Timeout = null;

    const handler = (
      event: CustomEvent<{ isAuthorized: boolean }>
    ) => {
      clearTimeout(timeout);
      document.removeEventListener(
        UserEvents.userGetAuthStateResponse,
        handler
      );

      resolve(event.detail.isAuthorized);
    };

    document.addEventListener(
      UserEvents.userGetAuthStateResponse,
      handler
    );
    document.dispatchEvent(
      new CustomEvent(UserEvents.userGetAuthStateRequest)
    );

    timeout = setTimeout(() => {
      document.removeEventListener(
        UserEvents.userGetAuthStateResponse,
        handler
      );

      const token =
        Cookies.get('user-token') ||
        localStorage.getItem('user-token');

      resolve(!!token);
    }, 300);
  });
