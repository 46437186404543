import React from 'react';
import { Modal } from '@npm-registry/eapteka-ui';
import { useDispatch } from 'react-redux';
import { closeModal } from '../../../store/screen';
import { AuthorizationSberIdForm } from '../AuthorizationSberIdForm';
import Styles from './AuthorizationSberIdModal.scss';

export const AuthorizationSberIdModal = ({
  onClose
}: {
  onClose?: () => void;
}) => {
  const dispatch = useDispatch();

  const closeHandler = () => {
    if (onClose) {
      onClose();
      return;
    }
    dispatch(closeModal());
  };

  return (
    <Modal
      className={Styles.authorizationSberIdModalWrapper}
      classNameContainer={Styles.authorizationSberIdModal}
      onClose={closeHandler}
      visible
    >
      <AuthorizationSberIdForm
        onClose={closeHandler}
        isModal
      />
    </Modal>
  );
};
