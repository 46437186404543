import { FormFields } from './form';

export enum ErrorCode {
  invalidEmail = 'invalidEmail',
  invalidPassword = 'invalidPassword',
  invalidPasswordConfirm = 'invalidPasswordConfirm',
  invalidPhone = 'invalidPhone',
  emailAuthRequestError = 'emailAuthRequestError',
  invalidCodeRequestError = 'invalidCodeRequestError',
  invalidEmailResetRequestError = 'invalidEmailResetRequestError',
  invalidPasswordSetRequestError = 'invalidPasswordSetRequestError'
}

export interface FieldError {
  fields: FormFields[];
  type: ErrorCode;
  message: string;
}

export type ErrorsList = FieldError[];

export type ErrorsMap = {
  [key in ErrorCode]: FieldError;
};
