import { createAsyncThunk } from '@reduxjs/toolkit';
import { ScreenView } from '../../types/screens';
import type { TRootState } from '../rootReducer';
import { screenStoreName } from './consts';
import { screenActions } from './slice';
import { getRedirectStatus } from './selectors';

export const closeModal = createAsyncThunk(
  screenStoreName + '/closeModal',
  async (_, thunkAPI) => {
    const isOnRedirect = getRedirectStatus(
      thunkAPI.getState() as TRootState
    );

    if (!isOnRedirect) {
      thunkAPI.dispatch(screenActions.changeScreen(null));
      thunkAPI.dispatch(
        screenActions.setView(ScreenView.Modal)
      );
    }
  }
);

export const redirectToSberAuth = createAsyncThunk(
  screenStoreName + '/redirectToSberAuth',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(screenActions.changeScreen(null));
  }
);
