import {
  validatePassword,
  validatePasswords
} from '../../../helpers/validators';
import { combineValidators } from '../../../helpers/validators/combineValidators';

export interface PasswordConfirmFromValidatorProps {
  password: string;
  passwordConfirm: string;
}

export const passwordConfirmFormValidator = async (
  props: PasswordConfirmFromValidatorProps
) =>
  await combineValidators([
    validatePassword(props.password),
    validatePasswords(props.password, props.passwordConfirm)
  ]);
