import React, { FormEvent, VFC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getRequestState,
  isPasswordResetFormSubmitDisabled,
  submitResetPassword,
  setResetEmailFormField
} from '../../../store/forms';
import { FormFields } from '../../../types/form';
import { FormWrapper } from '../../elements/FormWrapper';
import { BaseField } from '../../elements/BaseField';
import { PolicyBlock } from '../../elements/PolicyBlock';
import { FormGroup } from '../../elements/FormGroup';
import { SubmitFormButton } from '../../elements/SubmitFormButton';
import { PolicyNotice } from '../../elements/PolicyNotice';
import { ResetPasswordFormProps } from './types';
import Styles from './ResetPasswordForm.scss';

export const ResetPasswordForm: VFC<
  ResetPasswordFormProps
> = ({ onClose }) => {
  const dispatch = useDispatch();
  const isRequestInProgress = useSelector(getRequestState);
  const isSubmitDisable = useSelector(
    isPasswordResetFormSubmitDisabled
  );

  const onSubmitHandler = (event: FormEvent) => {
    event.preventDefault();

    dispatch(submitResetPassword());
  };

  return (
    <FormWrapper
      title="Восстановить пароль"
      onClose={onClose}
    >
      <div className={Styles.resetPasswordFormModal}>
        <form
          className={Styles.resetPasswordForm}
          onSubmit={onSubmitHandler}
        >
          <BaseField
            label="Почта"
            field={FormFields.email}
            setter={setResetEmailFormField}
          />
          <PolicyBlock />
          <FormGroup>
            <SubmitFormButton
              disabled={isSubmitDisable}
              isRequestInProgress={isRequestInProgress}
            >
              Восстановить пароль
            </SubmitFormButton>
          </FormGroup>
        </form>
        <PolicyNotice />
      </div>
    </FormWrapper>
  );
};
