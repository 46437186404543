import { createAsyncThunk } from '@reduxjs/toolkit';
import { loginByUserToken } from '../../../helpers/backwardCompatibility/loginByUserToken';
import { accountBlocked } from '../../../helpers/notifications/accountBlocked';
import {
  AnalyticEventsMap,
  AnalyticsEvents
} from '../../../helpers/analytics';
import { setTokensToStorage } from '../../../helpers/tokens/setTokensToStorage';
import { LoginFlow } from '../../../types/flows';
import type { LoginData } from '../../../types/api';
import { loginUserRequest } from '../../../api/auth';
import { serializeUser } from '../../user/serializers/serializeUser';
import { setUserAuthorize } from '../../user/actionCreators';
import { screenActions } from '../../screen';
import type { TRootState } from '../../rootReducer';
import {
  getPolicyState,
  getSubscriptionState
} from '../selectors';
import { formsStoreName } from '../consts';
import { formsActions } from '../slice';

export const loginUser = createAsyncThunk(
  formsStoreName + '/loginUser',
  async (
    { flow, errors, ...params }: LoginData,
    thunkAPI
  ) => {
    const store = thunkAPI.getState() as TRootState;

    thunkAPI.dispatch(formsActions.setRequestState(true));

    try {
      const result = await loginUserRequest({
        ...params,
        approvals: {
          subscriptions: getSubscriptionState(store),
          policy: getPolicyState(store)
        }
      });

      const serializedUser = serializeUser(result);

      AnalyticEventsMap[AnalyticsEvents.loginSuccessful](
        LoginFlow.email
      );

      await loginByUserToken({
        USER_TOKEN: serializedUser.userToken,
        CART_TOKEN: serializedUser.cartToken
      });

      setTokensToStorage(result, flow);

      if (serializedUser.favoriteStore) {
        localStorage.setItem(
          'favorite-store',
          serializedUser.favoriteStore
        );
      }

      if (
        !serializedUser.bonusProgram.emailVerified &&
        window.location.pathname.includes('personal')
      ) {
        sessionStorage.setItem(
          'hasPersonalReloadAfterAuth',
          'true'
        );
      }

      thunkAPI.dispatch(
        setUserAuthorize({ user: serializedUser })
      );

      if (window.isHostApp) {
        thunkAPI.dispatch(screenActions.changeScreen(null));
      } else {
        window.location.reload();
      }

      thunkAPI.dispatch(
        formsActions.setRequestState(false)
      );
    } catch (requestError) {
      if (requestError.status) {
        const response = requestError as Response;

        if (requestError.status === 510) {
          accountBlocked({ message: response.statusText });
        }
      } else {
        console.error('submitEmailAuthForm', requestError);

        AnalyticEventsMap[
          AnalyticsEvents.loginUnsuccessful
        ](requestError.status, LoginFlow.email);
      }

      if (errors) {
        thunkAPI.dispatch(
          formsActions.updateErrorsList(errors)
        );
      }

      thunkAPI.dispatch(
        formsActions.setRequestState(false)
      );
    }
  }
);
