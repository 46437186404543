import { createAsyncThunk } from '@reduxjs/toolkit';
import { sendSmsCode } from '../../../api/auth';
import { errorsMap } from '../../../consts/errors';
import { ScreenCode } from '../../../types/screens';
import { ErrorCode } from '../../../types/errors';
import { TRootState } from '../../rootReducer';
import { screenActions } from '../../screen';
import { phoneAuthFormValidator } from '../validators/phoneAuthForm';
import { formsStoreName } from '../consts';
import { formsActions } from '../slice';
import { getPhone } from '../selectors';

export const submitPhoneForm = createAsyncThunk(
  formsStoreName + '/submitPhoneForm',
  async (_, thunkAPI) => {
    const store = thunkAPI.getState() as TRootState;
    const phone = getPhone(store);

    const errorsList = await phoneAuthFormValidator({
      phone
    });

    if (!errorsList.length) {
      thunkAPI.dispatch(formsActions.setRequestState(true));

      try {
        const result = await sendSmsCode({
          phone
        });

        if (result.status === 200) {
          thunkAPI.dispatch(
            screenActions.changeScreen(ScreenCode.codeEnter)
          );
          thunkAPI.dispatch(
            formsActions.setSmsLastRequestTimestamp(
              Date.now()
            )
          );
        } else {
          thunkAPI.dispatch(
            formsActions.updateErrorsList([
              errorsMap[ErrorCode.invalidPhone]
            ])
          );
        }
      } catch (error) {
        thunkAPI.dispatch(
          formsActions.updateErrorsList([
            errorsMap[ErrorCode.invalidPhone]
          ])
        );
      }

      thunkAPI.dispatch(
        formsActions.setRequestState(false)
      );
    } else {
      thunkAPI.dispatch(
        formsActions.updateErrorsList(errorsList)
      );
    }
  }
);
