export const accountBlocked = ({
  title = 'Учетная запись заблокирована',
  ...props
}: {
  title?: string;
  message?: string;
}) => {
  document.dispatchEvent(
    new CustomEvent('appNotificationsPushItem', {
      detail: {
        id: 'account-blocked',
        className: 'AccountBlocked-Notification',
        title,
        ...props
      }
    })
  );
};
