import { ErrorCode } from '../../types/errors';
import { errorsMap } from '../../consts/errors';
import { validatorsGenerator } from './validatorsGenerator';

export const validatePhone = async (phone: string) =>
  validatorsGenerator([
    {
      condition: async () =>
        phone.replace(/[^0-9]/g, '').length !== 11,
      error: errorsMap[ErrorCode.invalidPhone]
    }
  ]);
