import { createRestApiPreset } from '@npm-registry/helpers/request';
import { getRequestTokensFromStorage } from '../helpers/tokens';
import { BASE_API_URL, API_HEADERS } from '../config';
import { ISOLATE_API_URL } from '../consts/api';

export const apiService = createRestApiPreset(
  BASE_API_URL,
  {
    headers: {
      ...API_HEADERS,
      ...getRequestTokensFromStorage()
    }
  }
);

export const isolateApiService = createRestApiPreset(
  ISOLATE_API_URL,
  {
    headers: API_HEADERS
  }
);
