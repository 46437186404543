import { AnalyticsEventParams } from './types';

export const dataLayerPush = (
  params: AnalyticsEventParams
) => {
  const dataLayer = window.dataLayer;

  if (dataLayer && dataLayer.push) {
    dataLayer.push(params);
  }
};
