const ua = navigator.userAgent.toLowerCase();

export const isAndroid = ua.search('android') > -1;
export const isMozilla = /firefox/.test(ua);
export const isChrome = /chrome/.test(ua);
export const isOpera = /opera/.test(ua);
export const isSamsung = /SamsungBrowser/.test(ua);
export const isSafari =
  /^((?!chrome|android).)*safari/i.test(ua);
export const isIos = /iPad|iPhone|iPod/.test(
  navigator.userAgent
);
