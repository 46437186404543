import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getScreen } from '../../../store/screen/selectors';
import { screensFormComponentsMap } from '../../../consts/screensComponentsMap';

export const AuthorizationForm = () => {
  const screen = useSelector(getScreen);

  const CurrentAuthComponent = useMemo(
    () => screensFormComponentsMap[screen],
    [screen]
  );

  if (screen === null) {
    return null;
  }

  return <CurrentAuthComponent />;
};
