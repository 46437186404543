import {
  validateEmail,
  validatePassword
} from '../../../helpers/validators';
import { combineValidators } from '../../../helpers/validators/combineValidators';

export interface EmailAuthFormValidatorProps {
  email: string;
  password: string;
}

export const emailAuthFormValidator = async (
  props: EmailAuthFormValidatorProps
) =>
  await combineValidators([
    validateEmail(props.email),
    validatePassword(props.password)
  ]);
