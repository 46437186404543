import { ErrorCode, type ErrorsMap } from '../types/errors';
import { FormFields } from '../types/form';

export const errorsMap: ErrorsMap = {
  [ErrorCode.invalidEmail]: {
    fields: [FormFields.email],
    type: ErrorCode.invalidEmail,
    message: 'Некорректный формат почты'
  },
  [ErrorCode.invalidPassword]: {
    fields: [FormFields.password],
    type: ErrorCode.invalidPassword,
    message: 'Пароль'
  },
  [ErrorCode.invalidPasswordConfirm]: {
    fields: [FormFields.passwordConfirm],
    type: ErrorCode.invalidPasswordConfirm,
    message: 'Значение полей не совпадает'
  },
  [ErrorCode.invalidPhone]: {
    fields: [FormFields.phone],
    type: ErrorCode.invalidPhone,
    message: 'Некорректный формат телефона'
  },
  [ErrorCode.emailAuthRequestError]: {
    fields: [FormFields.email, FormFields.password],
    type: ErrorCode.emailAuthRequestError,
    message: 'Неверная почта или пароль'
  },
  [ErrorCode.invalidCodeRequestError]: {
    fields: [FormFields.smsCode],
    type: ErrorCode.invalidCodeRequestError,
    message: 'Неверный код'
  },
  [ErrorCode.invalidEmailResetRequestError]: {
    fields: [FormFields.email],
    type: ErrorCode.invalidEmailResetRequestError,
    message: 'Пользователь не найден'
  },
  [ErrorCode.invalidPasswordSetRequestError]: {
    fields: [FormFields.password],
    type: ErrorCode.invalidPasswordSetRequestError,
    message:
      'Неверное значение контрольной строки. Попробуйте начать процедуру восстановления пароля сначала.'
  }
};
