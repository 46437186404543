import React, {
  type FormEvent,
  type VFC,
  useState,
  useMemo,
  useCallback,
  useEffect
} from 'react';
import { useSelector } from 'react-redux';
import {
  isPhoneAuthFormSubmitDisabled,
  submitSberIdForm
} from '../../../store/forms';
import { useAppDispatch } from '../../../store/store';
import { getPersonalizedButtonIsPersonalization } from '../../../store/sberId';
import { setLoginTime } from '../../../helpers/localStorage';
import { PersonalizedSberIdButton } from '../../elements/PersonalizedSberIdButton';
import { AuthorizationSberIdFormHOC } from '../AuthorizationSberIdFormHOC';
import { AuthorizationSberIdFormButton } from './AuthorizationSberIdFormButton/AuthorizationSberIdFormButton';
import type { AuthorizationSberIdFormProps } from './AuthorizationSberIdForm.types';
import { AuthorizationSberIdFormPhoneField } from './AuthorizationSberIdFormPhoneField';
import { useAuthorizationSberIdFormLoading } from './AuthorizationSberIdForm.hooks';

export const AuthorizationSberIdForm: VFC<
  AuthorizationSberIdFormProps
> = ({ onClose }) => {
  const dispatch = useAppDispatch();

  const [
    isLocalPersonalization,
    setIsLocalPersonalization
  ] = useState(true);

  const isReduxPersonalization = useSelector(
    getPersonalizedButtonIsPersonalization
  );
  const isSubmitDisabled = useSelector(
    isPhoneAuthFormSubmitDisabled
  );

  const { isLoading, disableLoading, enableLoading } =
    useAuthorizationSberIdFormLoading();

  useEffect(() => {
    if (isReduxPersonalization) {
      setIsLocalPersonalization(true);
    } else {
      setIsLocalPersonalization(false);
    }
  }, [isReduxPersonalization]);

  const onSubmitHandler = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      dispatch(submitSberIdForm());
      setLoginTime();
    },
    [dispatch]
  );

  const onChangeFormHandler = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      enableLoading();
      setIsLocalPersonalization(false);
      setTimeout(() => {
        disableLoading();
      }, 300);
    },
    [enableLoading, disableLoading]
  );

  const formElement = useMemo(() => {
    return isLocalPersonalization ? (
      <PersonalizedSberIdButton />
    ) : (
      <AuthorizationSberIdFormPhoneField />
    );
  }, [isLocalPersonalization]);

  return (
    <AuthorizationSberIdFormHOC
      formElement={formElement}
      buttonElement={
        <AuthorizationSberIdFormButton
          isPersonalization={isLocalPersonalization}
          onChangeForm={onChangeFormHandler}
          isSubmitDisabled={isSubmitDisabled}
        />
      }
      isLoading={isLoading}
      onSubmitHandler={onSubmitHandler}
      onClose={onClose}
    />
  );
};
