import { createSelector } from '@reduxjs/toolkit';
import type { TRootState } from '../rootReducer';
import type { SberIdStore } from './types';

export const getSberIdState = (
  state: TRootState
): SberIdStore => state.sberId;

export const getIsLoadingPersonalizedButton: (
  state: TRootState
) => boolean = createSelector(
  getSberIdState,
  (SberIdStore) => SberIdStore.isLoadingPersonalizedButton
);

export const getPersonalizedButtonIsPersonalization: (
  state: TRootState
) => boolean = createSelector(
  getSberIdState,
  (SberIdStore) => SberIdStore.isPersonalization
);
