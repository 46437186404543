// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fBqd6sf{padding-bottom:22px}.wn4oZHi{font-size:14px;line-height:18px;color:#4a4a4a}.H9nJXh0{width:100%;font-size:16px;font-weight:normal;line-height:24px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"resetPasswordSuccessForm": `fBqd6sf`,
	"resetPasswordSuccessFormInfo": `wn4oZHi`,
	"resetPasswordSuccessFormButton": `H9nJXh0`
};
export default ___CSS_LOADER_EXPORT___;
