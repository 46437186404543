import { SberidSDKConfig } from '@sberid/js-sdk/dist/typings/sberid-sdk';
import { setPersonalizedButtonElement } from './personalizedButtonService';
import { Dispatch } from '@reduxjs/toolkit';
import { sberIdActions } from '../../../store/sberId/slice';
import Styles from '../../../styles/PersonalizedSberIdButton/PersonalizedSberIdButton.scss';

const buttonElement = document.createElement('div');
buttonElement.classList.add(Styles.buttonHide);
document.body.appendChild(buttonElement);

export const initializeSberIdSDKPersonalizedButton = (
  dispatch: Dispatch
): void => {
  if (typeof window.SberidSDK === 'function') {
    dispatch(sberIdActions.setIsLoadingPersonalizedButton(true));

    try {
      const { BX, SberidSDK } = window;
      const oidcParams = BX.oidcParams;

      const sdkConfig: SberidSDKConfig = {
        debug: false,
        oidc: oidcParams,
        container: buttonElement,
        cloud: {
          enable: true
        },
        personalization: true,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        notification: {
          enable: false,
        },
        onPersonalizationStatus: (personalization: boolean) => {
          if (personalization) {
            setPersonalizedButtonElement(buttonElement);
            dispatch(sberIdActions.setIsPersonalization(true));
          }
        }
      };

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      new SberidSDK(sdkConfig);
    } catch (error) {
      console.error('Failed to initialize the SberID SDK:', error);
    } finally {
      dispatch(
        sberIdActions.setIsLoadingPersonalizedButton(false)
      );
    }
  } else {
    console.error('SberidSDK is not a function');
  }
};
