import { createAsyncThunk } from '@reduxjs/toolkit';
import { passwordConfirmRequest } from '../../../api/auth';
import { errorsMap } from '../../../consts/errors';
import { ErrorCode } from '../../../types/errors';
import { getCurrentRegionUrl } from '../../../helpers/getCurrentRegionUrl';
import type { TRootState } from '../../rootReducer';
import { passwordConfirmFormValidator } from '../validators/passwordConfirmForm';
import { formsActions } from '../slice';
import {
  getCheckWord,
  getEmail,
  getPassword,
  getPasswordConfirm
} from '../selectors';
import { formsStoreName } from '../consts';

export const submitPasswordConfirm = createAsyncThunk(
  formsStoreName + '/submitPasswordConfirm',
  async (_, thunkAPI) => {
    const store = thunkAPI.getState() as TRootState;
    const email = getEmail(store);
    const password = getPassword(store);
    const code = getCheckWord(store);

    const errorsList = await passwordConfirmFormValidator({
      password: password,
      passwordConfirm: getPasswordConfirm(store)
    });

    if (!errorsList.length) {
      thunkAPI.dispatch(formsActions.setRequestState(true));

      try {
        const result = await passwordConfirmRequest({
          email,
          password,
          code
        });

        if (result.status === 200) {
          location.pathname = getCurrentRegionUrl();
        } else {
          thunkAPI.dispatch(
            formsActions.updateErrorsList([
              errorsMap[
                ErrorCode.invalidPasswordSetRequestError
              ]
            ])
          );
        }
      } catch (error) {
        thunkAPI.dispatch(
          formsActions.updateErrorsList([
            errorsMap[
              ErrorCode.invalidPasswordSetRequestError
            ]
          ])
        );
      }

      thunkAPI.dispatch(
        formsActions.setRequestState(false)
      );
    } else {
      thunkAPI.dispatch(
        formsActions.updateErrorsList(errorsList)
      );
    }
  }
);
