import type { User } from '../../../types/user';
import { UserEvents } from '../../../helpers/events/userEvents';

export const sendUserAuthorizedEvent = (
  user: User | null,
  isAuthorized: boolean
) => {
  document.dispatchEvent(
    new CustomEvent(UserEvents.userAuthorized, {
      detail: user
    })
  );
  document.dispatchEvent(
    new CustomEvent(UserEvents.userGetAuthStateResponse, {
      detail: {
        isAuthorized
      }
    })
  );
};
