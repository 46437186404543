import React from 'react';
import clsx from 'clsx';
import Styles from '../../../styles/PersonalizedSberIdButton/PersonalizedSberIdButton.scss';
import {
  usePersonalizedButtonWithPolicyConfirmOnClick,
  useSberIdPersonalizedButtonElement
} from './PersonalizedSberIdButton.hooks';

export const PersonalizedSberIdButton = () => {
  const buttonElement =
    useSberIdPersonalizedButtonElement();

  const { buttonRef, isPolicyConfirm } =
    usePersonalizedButtonWithPolicyConfirmOnClick(
      buttonElement
    );

  return (
    <div
      className={clsx(Styles.button, {
        [Styles.buttonDisabled]: !isPolicyConfirm
      })}
      ref={buttonRef}
    />
  );
};
