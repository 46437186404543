import { combineValidators } from '../../../helpers/validators/combineValidators';
import { validatePhone } from '../../../helpers/validators';

export interface SberIdAuthFromValidationProps {
  phone: string;
}

export const sberIdAuthFromValidation = async (
  props: SberIdAuthFromValidationProps
) => await combineValidators([validatePhone(props.phone)]);
