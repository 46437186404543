import React from 'react';
import { Provider } from 'react-redux';
import { AuthorizationModal } from '../../components/blocks/AuthorizationModal';
import { StoreRegistry } from '../../store';

export const AuthorizationEntryPoint = () => (
  <Provider store={StoreRegistry.instance.store}>
    <AuthorizationModal />
  </Provider>
);
