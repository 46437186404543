// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._rVFcTc{display:flex;justify-content:space-between;align-items:center;height:80px;margin-bottom:0}._rVFcTc button.aCJUwP4{--button-s-size: 40px;position:initial;right:-25px;display:flex;justify-content:center;align-items:center;width:40px;height:40px;margin:0;padding:0;color:#939395;border-radius:50%;background-color:#fff}h2.bp5EJGf{font-weight:600}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"head": `_rVFcTc`,
	"closeButton": `aCJUwP4`,
	"title": `bp5EJGf`
};
export default ___CSS_LOADER_EXPORT___;
