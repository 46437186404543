import React from 'react';
import PhoneField from '../../../elements/PhoneField';
import { FormFields } from '../../../../types/form';
import { setSberIdFormField } from '../../../../store/forms';

export const AuthorizationSberIdFormPhoneField = () => {

  return (
    <PhoneField
      label="Телефон"
      field={FormFields.phone}
      setter={setSberIdFormField}
    />
  );
};
