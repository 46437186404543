import {
  createSlice,
  type PayloadAction
} from '@reduxjs/toolkit';
import {
  type ScreenCode,
  ScreenView
} from '../../types/screens';
import { screenStoreName } from './consts';
import type { ScreenStore } from './types';

const getInitialState = (): ScreenStore => ({
  description: null,
  screen: null,
  view: ScreenView.Modal,
  isOnRedirect: false
});

const initialState = getInitialState();

export const screenSlice = createSlice({
  name: screenStoreName,
  initialState,
  reducers: {
    changeScreen: (
      state,
      { payload }: PayloadAction<ScreenCode | null>
    ) => {
      state.screen = payload;
    },
    setView: (
      state,
      { payload }: PayloadAction<ScreenView | null>
    ) => {
      state.view = payload;
    },
    setDescription: (
      state,
      { payload }: PayloadAction<string | null>
    ) => {
      state.description = payload;
    },
    setRebaseState: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isOnRedirect = payload;
    }
  }
});

const screenReducers = screenSlice.reducer;
const screenActions = screenSlice.actions;

export { screenReducers, screenActions, initialState };
