import { createSelector } from '@reduxjs/toolkit';
import type { Handbook } from '../../types/handbook';
import type { BonusInfo } from '../../types/sberBonuses';
import type { TRootState } from '../rootReducer';
import type { HandbookStore } from './types';

export const getHandbookState = (
  state: TRootState
): HandbookStore => state.handbook;

export const getFeatures: (
  state: TRootState
) => Handbook | null = createSelector(
  getHandbookState,
  (handbookStore) => handbookStore.features
);

export const getBonusInfo: (
  state: TRootState
) => BonusInfo | null = createSelector(
  getHandbookState,
  (handbookStore) => handbookStore.bonusInfo
);

export const getOnlySberId: (state: TRootState) => boolean =
  createSelector(
    getFeatures,
    (features) => features?.onlySberId
  );

export const getAuthPrioritizeSberId: (
  state: TRootState
) => boolean = createSelector(
  getFeatures,
  (features) => features?.authPrioritizeSberId
);

export const getFallbackInsteadSberId: (
  state: TRootState
) => boolean = createSelector(
  getFeatures,
  (features) => features?.fallbackInsteadSberId
);

export const getIsPersonalButtonAuthEnabled: (
  state: TRootState
) => boolean = createSelector(
  getFeatures,
  (features) => features?.isPersonalButtonAuthEnabled
);


export const getHandbookReceivedState: (
  state: TRootState
) => boolean = createSelector(
  getHandbookState,
  (handbookStore) => handbookStore.isFeaturesRequestReceive
);

export const getIsAutoRegisterSberSpasiboToOauthProp: (
  state: TRootState
) => boolean = createSelector(
  getFeatures,
  (features) => features.isAutoRegisterSberSpasiboToOauth
);

export const getBonusInfoProgCond: (
  state: TRootState
) => string | undefined = createSelector(
  getBonusInfo,
  (bonusInfo) => bonusInfo?.consentDocs?.progCond
);
