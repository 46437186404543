import type { User } from '../../types/user';
import { USER_EXTERNAL_REQUEST_TIMEOUT } from '../../config';

export enum UserEvents {
  userAuthorized = 'userAuthorized',
  userGetBroadcast = 'userGetBroadcast',
  userSetBroadcast = 'userSetBroadcast',
  userLogout = 'userLogout',
  userGetAuthStateRequest = 'userGetAuthStateRequest',
  userGetAuthStateResponse = 'userGetAuthStateResponse'
}

export const logoutUser = () => {
  document.dispatchEvent(
    new CustomEvent(UserEvents.userLogout)
  );
};

export const subscribeToUserAuthorization = (
  callback: (user: User | null) => void
) => {
  const userAuthHandler = (
    event: CustomEvent<User | null>
  ) => {
    callback(event.detail);
  };

  document.addEventListener(
    UserEvents.userAuthorized,
    userAuthHandler
  );

  return () => {
    document.removeEventListener(
      UserEvents.userAuthorized,
      userAuthHandler
    );
  };
};

export const getUserData = (): Promise<User> =>
  new Promise((resolve, reject) => {
    const timeOut = setTimeout(
      () => reject(null),
      USER_EXTERNAL_REQUEST_TIMEOUT
    );

    const broadcastHandler = (event: CustomEvent<User>) => {
      document.removeEventListener(
        UserEvents.userSetBroadcast,
        broadcastHandler
      );
      clearTimeout(timeOut);

      resolve(event.detail);
    };

    document.addEventListener(
      UserEvents.userSetBroadcast,
      broadcastHandler
    );

    document.dispatchEvent(
      new CustomEvent(UserEvents.userGetBroadcast)
    );
  });
