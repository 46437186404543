export const getDefaultCity = (): {
  title?: string;
  url?: string;
} => {
  const config = document.getElementById(
    'headerCity-config'
  );

  if (config) {
    const {
      currentCity: { title, url }
    } = JSON.parse(config.textContent);

    return { title, url };
  }

  return {};
};
