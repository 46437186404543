export enum LoginFlow {
  email = 'email',
  phone = 'phone',
  sberid = 'sberid'
}

export enum SberFlowInitiator {
  ssoApp = 'ssoApp',
  ssoWeb = 'ssoWeb',
  authBtn = 'authBtn'
}
