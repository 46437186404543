import { createAsyncThunk } from '@reduxjs/toolkit';
import { handbookRequest } from '../../api/handbook';
import {
  sberBonusesInfoRequest,
  sberBonusesRequest
} from '../../api/sberBonuses';
import { ClientStatuses } from '../../types/sberBonuses';
import { logoutUser } from '../user/actionCreators';
import { getTokensFromStorage } from '../../helpers/tokens/getTokensFromStorage';
import type { TRootState } from '../rootReducer';
import { serializeHandbook } from './serializers/serializeHandbook';
import { serializeBonusInfo } from './serializers/serializeBonusInfo';
import { getIsAutoRegisterSberSpasiboToOauthProp } from './selectors';
import { handbookStoreName } from './consts';
import { handbookAction } from './slice';
import { LOGIN_TIME } from '../../consts/localStorage';

export const checkSberSpasiboAutoLogout = createAsyncThunk(
  handbookStoreName + '/checkSberSpasiboAutoLogin',
  async (_, thunkAPI) => {
    const tokens = getTokensFromStorage();
    const isAutoRegisterSberSpasiboToOauth =
      getIsAutoRegisterSberSpasiboToOauthProp(
        thunkAPI.getState() as TRootState
      );

    if (
      isAutoRegisterSberSpasiboToOauth &&
      tokens.userToken
    ) {
      try {
        const sberBonusesData = await sberBonusesRequest();

        if (
          sberBonusesData?.clientState === ClientStatuses.R
        ) {
          const loginTimeStr =
            localStorage.getItem(LOGIN_TIME);

          if (!loginTimeStr) {
            thunkAPI.dispatch(logoutUser());
          }

          const loginTime = parseInt(loginTimeStr, 10);
          if (isNaN(loginTime)) {
            return;
          }

          const currentTime = Math.floor(
            new Date().getTime() / 1000
          );
          const timeSpent = currentTime - loginTime;

          if (timeSpent >= 86400) {
            thunkAPI.dispatch(logoutUser());
          }
        }
      } catch (error) {
        console.error(
          'Sber Bonuses Request failed with error: ',
          error
        );
      }
    }
  }
);

export const initHandbook = createAsyncThunk(
  handbookStoreName + '/getHandbook',
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        handbookAction.setHandbook(
          serializeHandbook(await handbookRequest())
        )
      );
    } catch (error) {
      console.error(
        'Handbook request failed with error :',
        error
      );
    }

    thunkAPI.dispatch(handbookAction.setRequestState(true));

    thunkAPI.dispatch(checkSberSpasiboAutoLogout());
  }
);

export const getSberBonusesInfo = createAsyncThunk(
  handbookStoreName + '/getBonusInfo',
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        handbookAction.setBonusInfo(
          serializeBonusInfo(await sberBonusesInfoRequest())
        )
      );
    } catch (error) {
      console.error(
        'Handbook Bonuses info request failed with error :',
        error
      );
    }
  }
);
