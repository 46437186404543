// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oRQ1MCr{padding-top:16px;padding-bottom:40px}.eAOzKuH{width:160px;height:46px;border-radius:8px}.xy43T1e input{width:33px;caret-color:#8168f0;border:1px solid #e2e2e2;box-shadow:0 0 0 30px #fff inset;transition:border ease .3s,background-color ease .3s;caret-shape:bar}.IZ49Ba8 input{border:1px solid #feb3d2;background-color:#ffeff3;background-image:none;box-shadow:none}.xy43T1e input:focus{border-color:#8168f0;background-color:#fff;box-shadow:0 1px 8px rgba(129,104,240,.2),0 0 0 30px #fff inset}.xy43T1e>div:first-child>div{margin-right:7px}.xy43T1e>div:first-child>div::after{content:none}.uxs1QP4{display:flex;justify-content:flex-start;align-items:center;margin-bottom:22px}.naRC6Zl{display:inline-flex;font-size:14px;line-height:20px;color:#f39}.zMd5y1a{margin-bottom:12px}.eRaqYU9[type=button]{padding:0}.eRaqYU9[type=button],.zMd5y1a{font-size:14px;line-height:20px}.x86G7Ds{margin-right:5px}.u003OzV{margin-top:80px;text-align:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"authorizationCodeForm": `oRQ1MCr`,
	"authorizationCodeFormFieldSkeleton": `eAOzKuH`,
	"authorizationCodeFormField": `xy43T1e`,
	"authorizationCodeFormFieldError": `IZ49Ba8`,
	"authorizationCodeFormFieldGroup": `uxs1QP4`,
	"authorizationCodeFormError": `naRC6Zl`,
	"authorizationCodeFormInfo": `zMd5y1a`,
	"authorizationCodeFormLink": `eRaqYU9`,
	"authorizationCodeFormPhone": `x86G7Ds`,
	"authorizationCodeEmailLogin": `u003OzV`
};
export default ___CSS_LOADER_EXPORT___;
