import type {
  Sberprime,
  SberprimeResponse
} from '../../../types/user';

export const serializeSberprime = (
  sberprime: SberprimeResponse | null | undefined
): Sberprime | null => {
  if (!sberprime) {
    return null;
  }

  return {
    expire: sberprime.expire,
    subscriptionType: sberprime.subscription_type,
    image: sberprime.image,
    imageShort: sberprime.image_short,
    privilege: sberprime.privilege,
    cashback: sberprime.cashback,
    manageLink: sberprime.manage_link,
    delivery: {
      freeDisabled: sberprime.delivery.free_disabled,
      freeFrom: sberprime.delivery.free_from,
      price: sberprime.delivery.price
    }
  };
};
