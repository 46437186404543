import React, { type FC, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AuthorizationForm,
  ScreenCode,
  ScreenView
} from '@front-office/app-authorization';
import { getHandbookReceivedState } from '../../store/handbook';
import { showStartScreen } from '../../store/screen/thunks/showStartScreen';
import { AuthorizationFormEntryPointHocProps } from './types';

export const AuthorizationFormEntryPointHoc: FC<
  AuthorizationFormEntryPointHocProps
> = ({ email, screen }) => {
  const dispatch = useDispatch();
  const isHandbookReceived = useSelector(
    getHandbookReceivedState
  );

  useLayoutEffect(() => {
    let hookHandler = () => {
      if (isHandbookReceived) {
        let currentScreen = undefined;

        if (screen && screen in ScreenCode) {
          currentScreen = screen;
        } else if (email && email !== 'false') {
          currentScreen = ScreenCode.emailEnter;
        }

        dispatch(
          showStartScreen({
            view: ScreenView.Form,
            screen: currentScreen
          })
        );

        hookHandler = null;
      }
    };

    if (hookHandler !== null) {
      hookHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHandbookReceived]);

  return <AuthorizationForm />;
};
