// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/images/backgroundModal.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ni8WYwE{border-radius:24px}.m6PbbBe{width:450px;background:none}.u5IcFfB{width:450px;background-image:url(${___CSS_LOADER_URL_REPLACEMENT_0___});background-repeat:no-repeat;background-position:0% 100%;background-size:cover}.TncxWpu{padding:10px}.CmFZOKw{color:#9199a3}.Dp6G9k2{position:absolute;top:14px;right:14px;background-color:#f6f7fa}.qmVJpIY{margin:12px 0 48px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalAuth": `ni8WYwE`,
	"modalContainer": `m6PbbBe`,
	"modalContainerBackground": `u5IcFfB`,
	"modalContainerInner": `TncxWpu`,
	"modalButtonCloseIcon": `CmFZOKw`,
	"modalButtonClose": `Dp6G9k2`,
	"modalTitle": `qmVJpIY`
};
export default ___CSS_LOADER_EXPORT___;
