import React from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  ButtonSizes
} from '@npm-registry/eapteka-ui';
import { getRequestState } from '../../../../store/forms';
import SubmitFormButton from '../../../elements/SubmitFormButton';
import type { AuthorizationSberIdFormButtonProps } from './AuthorizationSberIdFormButton.types';
import Styles from './AuthorizationSberIdFormButton.scss';

export const AuthorizationSberIdFormButton = ({
  isPersonalization,
  isSubmitDisabled,
  onChangeForm
}: AuthorizationSberIdFormButtonProps) => {
  const isRequestInProgress = useSelector(getRequestState);

  if (isPersonalization) {
    return (
      <Button
        className={Styles.button}
        size={ButtonSizes.s}
        theme="light"
        onClick={onChangeForm}
      >
        Сменить пользователя
      </Button>
    );
  }

  return (
    <SubmitFormButton
      disabled={isSubmitDisabled}
      isRequestInProgress={isRequestInProgress}
    >
      Войти
    </SubmitFormButton>
  );
};
