import type {
  BonusInfo,
  BonusInfoResponse
} from '../../../types/sberBonuses';

export const serializeBonusInfo = (
  response: BonusInfoResponse
): BonusInfo => ({
  withdraw: {
    header: response?.withdraw?.header,
    card: response?.withdraw?.card,
    sberprime: response?.withdraw?.sberprime,
    limit: response?.withdraw?.limit
  },
  earn: {
    header: response?.earn?.header,
    exchangeBonus: response?.earn?.exchange_bonus,
    exchangeRub: response?.earn?.exchange_rub,
    text: response?.earn?.text
  },
  earnInfo: (response?.earn_info || []).map(
    (earnInfoItem) => ({
      minOrderSum: earnInfoItem.min_order_sum,
      percent: earnInfoItem.percent
    })
  ),
  consentDocs: {
    progCond: response?.consent_docs?.prog_cond,
    dataProc: response?.consent_docs?.data_proc,
    newsOffers: response?.consent_docs?.news_offers
  }
});
